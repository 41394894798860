<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <ul id="cashDrawerTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="first-cashDrawer" class="nav-link" data-bs-toggle="tab" data-bs-target="#firstCashDrawer"
              type="button" role="tab" aria-controls="firstCashDrawer" aria-selected="true" @click="onClickTab('1')">
              {{ $t("content.amnclosebankdrawer") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-cashDrawer" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondCashDrawer"
              type="button" role="tab" aria-controls="secondCashDrawer" aria-selected="false" @click="onClickTab('2')">
              {{ $t("content.amnbankdrawerman") }}
            </button>
          </li>
        </ul>

        <div id="cashDrawerTabContent" class="tab-content">
          <div id="firstCashDrawer" class="tab-pane fade" role="tabpanel" aria-labelledby="first-cashDrawer">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnclosebankdrawer") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.sdate") }}</label>
                    <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                      fluid :text-input="textInputOptions" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t("content.edate") }}</label>
                    <VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true"
                      :max-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                      fluid :text-input="textInputOptions" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">
                      {{ $t("content.cbankacc") }}</label>
                    <select class="form-select" v-model="bankacc_search">
                      <option value=''> {{ $t("content.optall") }}
                      </option>
                      <option v-for="(item, index) in listbankAcc" :value="item.uid" :key="index">
                        {{ item.shortName }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3"
                      @click="loadDataApi" :disabled="start_date > end_date">
                      <i class="material-icons material-symbols-outlined">search</i> {{ $t("buttons.sear") }}
                    </material-button>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.cbankacc") }}</th>
                          <th>{{ $t("content.amount") }}</th>
                          <th>{{ $t("content.note") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listclosebank" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ format_datetime(row.closingDateTime) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ filterBankacc(row.bankAccUid) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberDecimalString(row.totalAmount) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.remark }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.fundStatus }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button :disabled="row.fundStatus !== 'DRAFT'" color="info" variant="outline"
                              class="rounded-circle me-1" data-bs-toggle="tooltip" data-bs-placement="top"
                              :title="btnEdit" @click="editRowItems(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button :disabled="row.fundStatus !== 'DRAFT'" color="danger" variant="outline"
                              class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="deleteRowItems(row)">
                              <i class=" material-icons material-symbols-outlined">delete</i>
                            </material-button>
                            <material-button v-if="row.fundStatus === 'DRAFT'" color="success" variant="outline"
                              class="rounded-circle me-1" data-bs-toggle="tooltip" data-bs-placement="top"
                              :title="btnApprove" @click="onApprove(row)">
                              <i class="material-icons material-symbols-outlined">done_all</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listclosebank.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="11" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listclosebank.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="secondCashDrawer" class="tab-pane fade" role="tabpanel" aria-labelledby="second-cashDrawer">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnbankdrawerman") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{
                      $t("content.cbankacc")
                    }}</label>
                    <select class="form-select" v-model="closebank_detail.bankAccUid">
                      <option value="0" selected disabled>{{ $t("content.select") }}</option>
                      <option v-for="(item, index) in listbankAcc" :value="item.uid" :key="index">
                        {{ item.shortName }}
                      </option>
                    </select>
                  </div>
                  <div class="flex-auto col-lg-4">
                    <label class="form-label ms-0">
                      {{ $t("content.amount") }}
                    </label>
                    <div class="align-items-center">
                      <InputNumber v-model="closebank_detail.totalAmount" :maxFractionDigits="2" autocomplete="off"
                        @focus="$event.target.select()" fluid />
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <label class="form-label ms-0">{{ $t("content.note") }}</label>
                  <div class="col-lg-10">
                    <Textarea rows="3" v-model="closebank_detail.remark" class="w-100" :placeholder="note"
                      style="resize: none" />
                  </div>
                </div>
                <hr />
                <div class="row mt-3">
                  <div class="col-lg-10">
                    <!-- <Textarea rows="3" v-model="closebank_detail.remark" class="w-100" :placeholder="note"
                      style="resize: none" /> -->
                  </div>
                  <div class="col-lg-2">
                    <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="confirmNewRecord"
                      :disabled="closebank_detail.bankAccUid === '0' || closebank_detail.totalAmount === 0 || closebank_detail.totalAmount === null || closebank_detail.remark.trim() === ''">
                      <i class="material-icons material-symbols-outlined">save</i>
                      {{ btnSave }}
                    </material-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, inject, computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';

import moment from 'moment'
import ExampleFive from "@/examples/ExampleOne";

import { useCashAccountStore } from '@/stores/cashaccount'
import { useShopBankStore } from '@/stores/shopbank'
import { useCloseBankStore } from '@/stores/closebank'

const storec = useCashAccountStore()
const storesb = useShopBankStore()
const storecb = useCloseBankStore()

const { t } = useI18n();
const swal = inject("$swal");



const btnApprove = computed(() => t("buttons.apprv"))
const btnEdit = computed(() => t("buttons.edit"));
const btnSave = computed(() => t("buttons.save"));
const btnDel = computed(() => t("buttons.remo"));

const note = computed(() => t("content.note"));


const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};



function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}

const onClickHandler = () => {
  updatePagination()
};


const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const bankacc_search = ref('');
const start_date = ref(new Date(new Date().setHours(0, 0, 0, 0)));
const end_date = ref(new Date());
const shop_detail = ref(null)
const listbankAcc = ref([]);
const listclosebank = ref([]);
const closebank_detail = ref({
  uid: '',
  bankAccUid: '0',
  closingDateTime: new Date(),
  totalBillCount: 0,
  totalAmount: 0,
  remark: '',
  fundStatus: '',
  version: '',
});


const shopInfoApi = async (val) => {
  shop_detail.value = null
  const response = await storec.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("cashAccShopApi : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        await loadBankPawnApi()
        await loadDataApi()

      }
    }
  }
}

shopInfoApi()






const loadBankPawnApi = async (val) => {
  listbankAcc.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    bankUid: '',
    shortName: '',
    acctNumber: '',
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesb.shopbankListFetch(payload);
  // console.log("loadBankPawnApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listbankAcc.value = response.data.data.list;

      }
    }
  }
}


const loadDataApi = async (val) => {
  let data = "";
  let sd = ''
  let ed = ''

  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')

  if (currentPage.value !== 1) {
    if (listclosebank.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }


  if (bankacc_search.value !== '' && val !== 'del') {
    currentPage.value = 1
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    bankAccUid: bankacc_search.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecb.closeBankListFetch(payload);
  // console.log("listclosebk : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listclosebank.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}


async function updatePagination() {
  listclosebank.value = []
  let data = "";
  let sd = ''
  let ed = ''
  sd = moment(new Date(start_date.value)).format('YYYY-MM-DD')
  ed = moment(new Date(end_date.value)).format('YYYY-MM-DD')

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    start: sd,
    end: ed,
    bankAccUid: bankacc_search.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecb.closeBankListFetch(payload);
  // console.log("listclosecash : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listclosebank.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}


async function addCloseBank() {
  const payload = closebank_detail.value
  payload.remark = payload.remark.trim()
  payload.closingDateTime = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
  // console.log('add p : ' + JSON.stringify(payload));
  const response = await storecb.closeBankAddFetch(payload);
  // console.log('add r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await backfirstTab()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateCloseBank() {
  const payload = closebank_detail.value
  payload.remark = payload.remark.trim()
  // console.log('update p : ' + JSON.stringify(payload));
  const response = await storecb.closeBankUpdateFetch(payload);
  // console.log('update r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await backfirstTab()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function deleteCloseBank(uid) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('delete p : ' + JSON.stringify(payload));
  const response = await storecb.closeBankDeleteFetch(payload);
  // console.log('delete r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function approveCloseBank(uid) {
  let payload = ""
  payload = {
    uid: uid
  }
  // console.log('approve p : ' + JSON.stringify(payload));
  const response = await storecb.closeBankApproveFetch(payload);
  // console.log('approve r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}



async function onClickTab(val) {
  if (val === '1') {
    if (end_date.value >= start_date.value) {
      loadDataApi()
    }
    await setfirstTab()
  } else if (val === '2') {
    await setsecondTab()
  }
}

async function backfirstTab() {
  clearItem()
  await onClickTab('1')

}


async function setfirstTab() {
  if (document.getElementById("second-cashDrawer")) {
    document.getElementById("second-cashDrawer").classList.remove('show', 'active')
  }

  if (document.getElementById("secondCashDrawer")) {
    document.getElementById("secondCashDrawer").classList.remove('show', 'active')
  }

  if (document.getElementById("first-cashDrawer")) {
    document.getElementById("first-cashDrawer").classList.add('show', 'active')
  }

  if (document.getElementById("firstCashDrawer")) {
    document.getElementById("firstCashDrawer").classList.add('show', 'active')
  }
}


async function setsecondTab() {
  if (document.getElementById("first-cashDrawer")) {
    document.getElementById("first-cashDrawer").classList.remove('show', 'active')
  }

  if (document.getElementById("firstCashDrawer")) {
    document.getElementById("firstCashDrawer").classList.remove('show', 'active')
  }
  if (document.getElementById("second-cashDrawer")) {
    document.getElementById("second-cashDrawer").classList.add('show', 'active')
  }

  if (document.getElementById("secondCashDrawer")) {
    document.getElementById("secondCashDrawer").classList.add('show', 'active')
  }


}


function filterBankacc(str) {
  let name = listbankAcc.value.filter((c) => c.uid === str);
  return name[0].shortName;
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined && num !== '') {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}


function clearItem() {
  closebank_detail.value = {
    uid: '',
    bankAccUid: '0',
    closingDateTime: new Date(),
    totalBillCount: 0,
    totalAmount: 0,
    remark: '',
    fundStatus: '',
    version: '',
  }
}

async function confirmNewRecord() {
  swal({
    title: "บันทึกธุรกรรม",
    text: "คุณต้องการบันทึกธุรกรรมนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (closebank_detail.value.uid === '') {
        addCloseBank()
      } else {
        updateCloseBank()
      }
    }
  });
}


async function onApprove(item) {
  swal({
    title: "ตรวจสอบรายการ",
    text: "คุณต้องการอนุมัติรายการนี้",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== '') {
        approveCloseBank(item.uid)
      }
    }
  });
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteCloseBank(item.uid)
      }
    }
  });
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      if (item.uid !== '') {
        closebank_detail.value = {
          uid: item.uid,
          bankAccUid: item.bankAccUid,
          closingDateTime: item.closingDateTime,
          totalBillCount: item.totalBillCount,
          totalAmount: item.totalAmount,
          remark: item.remark,
          fundStatus: item.fundStatus,
          version: item.version,
        }
        setsecondTab()
      }

    }
  })
}

onMounted(() => {

  if (document.getElementById("first-cashDrawer")) {
    document.getElementById("first-cashDrawer").classList.add('show', 'active')
  }

  if (document.getElementById("firstCashDrawer")) {
    document.getElementById("firstCashDrawer").classList.add('show', 'active')
  }
});
</script>

<style lang="scss" scoped></style>
