<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="homeTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="first-home-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#firstHome" type="button"
              role="tab" aria-controls="firstHome" aria-selected="true" @click="onClickTab('1')">
              {{ $t("content.amngroupticket") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-home-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondHome"
              type="button" role="tab" aria-controls="secondHome" aria-selected="false" @click="onClickTab('2')">
              {{ $t("content.amnalljobs") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="third-home-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#thirdHome" type="button"
              role="tab" aria-controls="thirdHome" aria-selected="false" :disabled="workcaseDetail === null"
              @click="onClickTab('3')">
              {{ $t("content.amnconfirmtxn") }}
            </button>
          </li>
        </ul>

        <div id="homeTabContent" class="tab-content">
          <div id="firstHome" class="tab-pane fade" role="tabpanel" aria-labelledby="first-home-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amngroupticketsearch") }}</h5>
              </div>

              <div class="card-body pt-0">
                <div class="row mt-2">
                  <div class="col-12 d-flex">
                    <div class="me-3">
                      <material-button color="success" class="float-lg-start float-sm-end mb-3" @click="onOpenQRModal">
                        <i class="material-icons material-symbols-outlined">qr_code_scanners</i>
                        {{ $t("buttons.sscan") }}
                      </material-button>
                      <material-button color="success" class="float-lg-start float-sm-end mb-3" data-bs-toggle="modal"
                        data-bs-target="#qrcodeModal" id="qrModalShow" v-show="false">
                      </material-button>
                    </div>
                    <div class="col-8 me-3">
                      <material-input type="text" v-model="qr_serach" :placeholder="t(`content.amnjobcode`)"
                        @keyup.enter="setResultQRTab1" />
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12">
                    <span class="form-label m-0">{{ $t("content.amnselectauto") }}</span>

                    <div class="d-inline-block mx-4">
                      <material-checkbox id="asGroup" v-model="selectGroup" :checked="selectGroup" name="autoSelect"
                        :disabled="selectType" @change="onSelectGroup">
                        {{ $t("content.amnasgroup") }}
                      </material-checkbox>
                    </div>

                    <div class="d-inline-block me-4">
                      <material-checkbox id="asType" v-model="selectType" :checked="selectType" name="autoSelect"
                        :disabled="selectGroup" @change="onSelectType">
                        {{ $t("content.amnastype") }}
                      </material-checkbox>
                    </div>

                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th></th>
                          <th>#</th>
                          <th>{{ $t("content.jobId") }}</th>
                          <th>{{ $t("content.trayId") }}</th>
                          <th>{{ $t("content.amndatetime") }}</th>
                          <th>{{ $t("content.tkOwner") }}</th>
                          <th>{{ $t("content.oldname") }}</th>
                          <th>{{ $t("content.refnumber") }}</th>
                          <th>{{ $t("content.payside") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnweightvalall") }}</th>
                          <th>{{ $t("content.amnqtyunitall") }}</th>
                          <th>{{ $t("content.amnanalysval") }}</th>
                          <th>{{ $t("content.amnmaxvalall") }}</th>
                          <th>{{ $t("content.amnreceiveprofit") }}</th>
                          <th>{{ $t("content.gpay") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                          <!-- <th>{{ $t("content.act") }}</th> -->
                        </tr>
                      </thead>

                      <tbody data-group-index="0">

                        <tr v-for="(row, index) in listQ03" :key="index" :class="row.paySide == 'PAY' && row.migration ? 'bg-redeem'
                          : row.paySide == 'PAY' && !row.migration ? 'bg-payment'
                            : row.paySide === 'RECEIVE' && row.migration ? 'bg-reduce-cap'
                              : row.paySide === 'RECEIVE' && !row.migration ? 'bg-receive'
                                : row.paySide === 'NONE' && row.migration ? 'bg-nonemi' : 'bg-none'">
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="row.chk" :checked="row.chk" :disabled="(selectGroup && row.paymentGroupUid !== groupuid && groupuid !== '')
                              || (selectType && row.type !== typeTrans && typeTrans !== '')"
                              @change="onSelectListQ02(row)">
                            </material-checkbox>
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString(index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.jobId }}</td>
                          <td class="text-sm font-weight-normal">{{ row.trayId }}</td>
                          <td class="text-sm font-weight-normal">{{ row.start }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.represent">
                              {{ row.tkCustomerName }}
                            </span>
                            <span v-else>
                              {{ row.customerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.tkCustomerName }}
                            </span>
                          </td>

                          <td class="text-sm font-weight-normal">
                            {{ row.initRefNumber }}
                            <!-- <span v-if="row.type === 'PAWN'">
                              {{ row.refNumber }}
                            </span>
                            <span v-else>
                              {{ row.initRefNumber }}
                            </span> -->
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.payside }}</td>
                          <td class="text-sm font-weight-normal">{{ row.trans }}</td>
                          <td class="text-sm font-weight-normal">{{ row.weight_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.quantity_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.newPawnValue_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.fullValue_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.interest_s }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.migration">
                              <span
                                v-if="row.migrationType === 'PAWN' || row.migrationType === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.type === 'PAWN' || row.type === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.latestUserName }}</td>

                        </tr>
                      </tbody>

                    </table>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 d-flex justify-content-end">
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="chkSelect">
                        <i class="material-icons material-symbols-outlined">lock</i>
                        {{ $t("content.amnselectlist") }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondHome" class="tab-pane fade" role="tabpanel" aria-labelledby="second-home-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnalljobs") }}</h5>
              </div>

              <div class="card-body pt-0">
                <div class="row mt-2">
                  <div class="col-12 d-flex">
                    <div class="me-3">
                      <material-button color="success" class="float-lg-start float-sm-end mb-3" @click="onOpenQRModal">
                        <i class="material-icons material-symbols-outlined">qr_code_scanners</i>
                        {{ $t("buttons.sscan") }}
                      </material-button>
                      <material-button color="success" class="float-lg-start float-sm-end mb-3" data-bs-toggle="modal"
                        data-bs-target="#qrcodeModal" id="qrModalShow" v-show="false">
                      </material-button>
                    </div>
                    <div class="col-8 me-3">
                      <material-input type="text" v-model="qr_serach2" :placeholder="t(`content.amnjobcode`)"
                        @keyup.enter="setResultQRTab2" />
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>

                          <th>#</th>
                          <th>{{ $t("content.jobId") }}</th>
                          <th>{{ $t("content.trayId") }}</th>
                          <th>{{ $t("content.amndatetime") }}</th>
                          <th>{{ $t("content.tkOwner") }}</th>
                          <th>{{ $t("content.oldname") }}</th>
                          <th>{{ $t("content.refnumberold") }}</th>
                          <th>{{ $t("content.refnumbernew") }}</th>
                          <th>{{ $t("content.payside") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnweightvalall") }}</th>
                          <th>{{ $t("content.amnqtyunitall") }}</th>
                          <th>{{ $t("content.amnanalysval") }}</th>
                          <th>{{ $t("content.amnmaxvalall") }}</th>
                          <th>{{ $t("content.amnreceiveprofit") }}</th>
                          <th>{{ $t("content.gpay") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>

                      <tbody data-group-index="0">

                        <tr v-for="(row, index) in listQ03O" :key="index" :class="row.paySide == 'PAY' && row.migration ? 'bg-redeem'
                          : row.paySide == 'PAY' && !row.migration ? 'bg-payment'
                            : row.paySide === 'RECEIVE' && row.migration ? 'bg-reduce-cap'
                              : row.paySide === 'RECEIVE' && !row.migration ? 'bg-receive'
                                : row.paySide === 'NONE' && row.migration ? 'bg-nonemi' : 'bg-none'">

                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString(index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.jobId }}</td>
                          <td class="text-sm font-weight-normal">{{ row.trayId }}</td>
                          <td class="text-sm font-weight-normal">{{ row.start }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.represent">
                              {{ row.tkCustomerName }}
                            </span>
                            <span v-else>
                              {{ row.customerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.changeName">
                              {{ row.tkCustomerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.initRefNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.refNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.payside }}</td>
                          <td class="text-sm font-weight-normal">{{ row.trans }}</td>
                          <td class="text-sm font-weight-normal">{{ row.weight_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.quantity_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.newPawnValue_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.fullValue_s }}</td>
                          <td class="text-sm font-weight-normal">{{ row.interest_s }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.migration">
                              <span
                                v-if="row.migrationType === 'PAWN' || row.migrationType === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="row.type === 'PAWN' || row.type === 'INC_PRINCIPLE' && row.totalPay > 0">
                                {{ formatNumberDecimalString(row.totalPay) }}
                              </span>
                              <span v-else>
                                {{ formatNumberDecimalString(row.totalReceive) }}
                              </span>
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.latestUserName }}</td>
                          <!-- <td class="text-sm font-weight-normal">{{ row.step.code }}</td> -->
                          <td class="text-sm font-weight-normal">
                            <!-- :disabled="(row.step.code === 'S030'  && row.type === 'INC_PRINCIPLE' ) || (row.step.code === 'S050' && row.type !== 'INC_PRINCIPLE')"> -->
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.amnticketmanage')"
                              @click="openTab(row)" :disabled="canEdit(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.amnunlock')"
                              @click="onUnLockDialog(row)"
                              :disabled="row.step.code !== 'S030' && row.step.code !== 'S050'">
                              <i class="material-icons material-symbols-outlined">lock_open</i>
                            </material-button>
                            <!-- :disabled="row.step.code !== 'S040' && row.step.code !== 'S050' && row.step.code !== 'S051'
                                && row.step.code !== 'S060' && row.step.code !== 'S061' && row.step.code !== 'S062' || row.type === 'REDEEM'" -->
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.amncollec')"
                              @click="onKeepDialog(row)" :disabled="canKeep(row)">
                              <i class="material-icons material-symbols-outlined">shopping_cart</i>
                            </material-button>
                            <!-- :disabled="row.step.code !== 'S040' && row.step.code !== 'S050' || row.type === 'REDEEM'" -->
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('buttons.canct')"
                              @click="onCancelTicketDialog(row)" :disabled="canCancel(row)">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                            <!-- :disabled="row.step.code !== 'S040' && row.step.code !== 'S050' && row.step.code !== 'S051'
                            && row.step.code !== 'S060' && row.step.code !== 'S061' && row.step.code !== 'S062' || row.type === 'REDEEM' || !filterCanPrint(row)"  -->
                            <material-button color="dark" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('buttons.printp')"
                              @click="ticketGen(row)" :disabled="canPrint(row)">
                              <i class="material-icons material-symbols-outlined">print</i>
                            </material-button>
                            <!-- :disabled="row.step.code !== 'S040' || row.type === 'REDEEM'"  -->
                            <!-- :disabled="row.step.code !== 'S040' && row.step.code !== 'S050' && row.step.code !== 'S051'
                            && row.step.code !== 'S060' && row.step.code !== 'S061' && row.step.code !== 'S062' || row.type === 'REDEEM'" -->
                            <!-- <material-button color="secondary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('buttons.printpb')"
                              @click="addCanPrint(row)" :disabled="canKeep(row)">
                              <i class="material-icons material-symbols-outlined">description</i>
                            </material-button> -->

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="thirdHome" class="tab-pane fade" role="tabpanel" aria-labelledby="third-home-tab">
            <div class="card mt-4">
              <div class="card-header">
                <h5>{{ $t("content.persontinfo") }} ({{ $t("content.tkOwner") }} :
                  <span v-if="listQ03E.represent">
                    {{ listQ03E.tkCustomerName }}
                  </span>
                  <span v-else>
                    {{ listQ03E.customerName }}
                  </span>)

                </h5>
              </div>

              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.cnam') }} - {{ $t('content.clsnam')
                      + " (" + $t("content.th") + ")" }}</label>
                    <material-input v-model="cus_detail.nameTh" type="text" disabled />
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.cnam') }} - {{ $t('content.clsnam')
                      + " (" + $t("content.en") + ")" }}</label>
                    <material-input v-model="cus_detail.nameEn" type="text" disabled />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t("content.cmobile") }}1*</label>
                    <span v-if="cus_detail.cusShop !== null">
                      <InputMask v-model="cus_detail.cusShop.phoneNo1" mask="(999) 999-9999" fluid disabled />
                    </span>
                    <span v-else>
                      <material-input type="text" disabled />
                    </span>
                  </div>
                </div>
              </div>

              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 me-4">
                    <b>{{ $t("content.amnpawnlist") }}
                      <span v-if="listQ03E.initRefNumber !== '' && listQ03E.initRefNumber !== null">
                        ({{ $t("content.refnumber") }} : {{ listQ03E.initRefNumber }})
                      </span>
                    </b>
                  </h6>

                  <div class="col">
                    <div class="input-group">
                      <input v-model="cusName_search" type="text" class="form-control"
                        style="margin-bottom: 0 !important" aria-describedby="searchNameOwner" :placeholder="$t('buttons.sear') +
                          ' ' +
                          $t('content.amnassetowner') +
                          '/' +
                          $t('content.cabove')
                          " readonly />
                      <button id="searchNameOwner" class="btn btn-primary mb-0" type="button" data-bs-toggle="modal"
                        data-bs-target="#relationPersonalModal" disabled>
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="mb-3 pt-2" v-if="workcaseDetail !== null">
                  <div class="d-flex justify-content-between align-items-center">
                    <h6 class="mb-0 me-4">
                      <span v-if="workcaseDetail.migration" style="color:red;">
                        <b>{{ $t("content.migraref") }}</b>
                      </span>
                      <span v-else>
                        <b>{{ $t("content.migraref") }}</b>
                      </span>
                    </h6>
                    <div class="col">
                      <div class="input-group">
                        <material-input v-model="workcaseDetail.migrateRefNumber" class="hl-label" disabled="true" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row review mb-3 pt-2">
                  <div class="col-lg-3">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnhdweightalltick")
                    }}</label>
                    <material-input v-model="listQ03E.weight_s" class="hl-label" disabled="true" />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnhdanalysalltick")
                    }}</label>
                    <material-input v-model="listQ03E.newPawnValue_s" class="hl-label" disabled="true" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnhdmaxalltick")
                    }}</label>
                    <material-input v-model="listQ03E.fullValue_s" class="hl-label" disabled="true" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{ $t("content.cintrate") }}</label>
                    <material-input v-model="interestRate_s" class="hl-label" disabled="true" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{ $t("content.prointerest") }}</label>
                    <material-input v-model="promoint_val" class="hl-label" disabled="true" />
                  </div>

                  <!-- <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnhdanalysold")
                    }}</label>
                    <material-input v-model="listQ03E.pawnValue_s" class="hl-label" disabled="true" />
                  </div> -->

                  <!-- <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amndifference")
                    }}</label>
                    <material-input v-model="diff_s" class="hl-label" disabled="true" value="88,888,888.88" />
                  </div> -->

                  <div class="col-lg-3">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnhdweightrest")
                    }}</label>
                    <material-input v-model="balweight_s" class="hl-label" disabled="true" />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnhdanalysrest")
                    }}</label>
                    <material-input v-model="balprice_s" class="hl-label" disabled="true" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnhdmaxrest")
                    }}</label>
                    <material-input v-model="balfull_s" class="hl-label" disabled="true" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnreceiveprofit")
                    }}</label>
                    <material-input v-model="listQ03E.interest_s" class="hl-label" disabled="true" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.periodpromotion")
                    }}</label>
                    <material-input v-model="promoint_duration" class="hl-label" disabled="true" />
                  </div>

                  <!-- <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnhdanalysnew")
                    }}</label>
                    <material-input v-model="listQ03E.newPawnValue_s" class="hl-label" disabled="true"
                      value="88,888,888.88" />
                  </div> -->

                  <!-- <div class="col-lg-2">
                    <label class="form-label hl-label ms-0">{{
                      $t("content.amnhdvalticket")
                    }}</label>
                    <material-input v-model="listQ03E.pawnValue_s" class="hl-label" disabled="true"
                      value="88,888,888.88" />
                  </div> -->
                </div>

                <div class="row mt-4 no-gutters">
                  <div class="col-6 d-flex justify-content-end align-items-center">
                    <div class="left">
                      <div class="me-1">
                        <h6>
                          <b>{{ $t("content.amnassetdatasinfo") }}</b>
                        </h6>
                      </div>&nbsp;
                      <div class="me-4">
                        <h6 style="color:red" v-if="uid_edit !== '' && workcaseDetail !== null">
                          <b>( {{ listQ03E.trans }}
                            <span v-if="listQ03E.type === 'PAWN' || listQ03E.type === 'REDEEM'">
                              {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                            </span>
                            <span v-if="listQ03E.type === 'INTEREST'">
                              {{ formatNumberDecimalString(workcaseDetail.interest) }}
                            </span>
                            <span v-if="listQ03E.type === 'INC_PRINCIPLE' || listQ03E.type === 'DEC_PRINCIPLE'">
                              {{ formatNumberDecimalString(workcaseDetail.diffValue) }}
                            </span>
                            <i i class="inline-icon material-icons">keyboard_double_arrow_right</i> {{
                              $t("content.gpay") }} : </b>
                          <span v-if="listQ03E.migration" style="text-decoration:underline">
                            <span
                              v-if="listQ03E.migrationType === 'PAWN' || listQ03E.migrationType === 'INC_PRINCIPLE' && listQ03E.totalPay > 0">
                              <b>{{ formatNumberDecimalString(listQ03E.totalPay) }}</b>
                            </span>
                            <span v-else>
                              <b>{{ formatNumberDecimalString(listQ03E.totalReceive) }}</b>
                            </span>
                          </span>
                          <span v-else style="text-decoration:underline">
                            <span
                              v-if="listQ03E.type === 'PAWN' || listQ03E.type === 'INC_PRINCIPLE' && listQ03E.totalPay > 0">
                              <b>{{ formatNumberDecimalString(listQ03E.totalPay) }}</b>
                            </span>
                            <span v-else>
                              <b>{{ formatNumberDecimalString(listQ03E.totalReceive) }}</b>
                            </span>
                          </span>
                          <b> )</b>
                        </h6>
                      </div>
                    </div>
                  </div>


                  <div class="col-6 d-flex justify-content-end align-items-center">

                    <div class="me-3">
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="images_l.length === 0" @click="openGallery">
                        <i class="material-icons material-symbols-outlined">photo_library</i>
                        {{ $t("content.viewimg") }}
                      </material-button>
                      <material-button color="warning" class="float-lg-start float-sm-end mb-3" data-bs-toggle="modal"
                        data-bs-target="#lookupModal" id="myModalClose" v-show="false">
                        <i class="material-icons material-symbols-outlined">photo_library</i>
                        {{ $t("content.viewimg") }}
                      </material-button>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>

                          <th>#</th>
                          <th>{{ $t("content.amndatetime") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnassetgroup") }}</th>
                          <th>{{ $t("content.amnweightval") }}</th>
                          <th>{{ $t("content.amnqtyunit") }}</th>
                          <th>{{ $t("content.amnanalysval") }}</th>
                          <th>{{ $t("content.amnmaxval") }}</th>

                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr style="background-color: transparent;"> -->
                        <tr v-for="(row, index) in listinfo" :key="index">

                          <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                          <td class="text-sm font-weight-normal">
                            {{ listQ03E.start }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ listQ03E.trans }} </td>
                          <td class="text-sm font-weight-normal">{{ filterCat(row.mainCatUid) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberString(row.quantity) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.pawnValue)
                            }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.fullValue)
                            }}</td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr />

                <!-- <div class="row mt-4 no-gutters">
                  <div class="col-3 d-flex justify-content-between align-items-center">
                    <h6>
                      <b>{{ $t("content.amnassetdatas") }}</b>
                    </h6>
                  </div>
                </div> -->

                <div class="row mt-4 no-gutters">
                  <div class="col-12 d-flex justify-content-end align-items-center">
                    <div class="left">
                      <div class="me-2">
                        <h6>
                          <b>{{ $t("content.amnassetdatas") }}</b>
                        </h6>
                      </div>&nbsp;
                      <div class="me-2" v-if="uid_edit !== '' && workcaseDetail !== null">
                        <h6 style="color:red" v-if="workcaseDetail.workItems[0].represent">
                          ({{ $t("content.represent") }}
                          <i class="inline-icon material-icons">keyboard_double_arrow_right</i>
                          {{ workcaseDetail.workItems[0].customerName }})
                        </h6>
                        <h6 style="color:red" v-if="workcaseDetail.workItems[0].changeName">
                          ({{ $t("content.changeName") }}
                          <i class="inline-icon material-icons">keyboard_double_arrow_right</i>
                          {{ workcaseDetail.workItems[0].tkCustomerName }})
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>

                          <th>#</th>
                          <th>{{ $t("content.amndatetime") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnassetgroup") }}</th>
                          <th>{{ $t("content.amnweightval") }}</th>
                          <th>{{ $t("content.amnqtyunit") }}</th>
                          <th>{{ $t("content.amnanalysval") }}</th>
                          <th>{{ $t("content.amnmaxval") }}</th>

                        </tr>
                      </thead>
                      <tbody v-for="(row, index) in listQ03D" :key="index">
                        <!-- <tr style="background-color: transparent;"> -->
                        <tr class="group-id-ticket" :class="row.st == 'finished' ? '' : 'bg-working'">
                          <td class="text-sm font-weight-normal">
                            <div class="group-tickect" style="padding: 0.5rem !important">
                              {{ formatNumberString(index + 1) }}
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">{{ listQ03E.start }}</td>
                          <td class="text-sm font-weight-normal">{{ listQ03E.trans }}</td>
                          <td class="text-sm font-weight-normal">{{ filterCat(row.mainCatUid) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberString(row.quantity) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="listQ03E.type !== 'INC_PRINCIPLE' && listQ03E.type !== 'DEC_PRINCIPLE'">
                              {{ formatNumberDecimalString(row.newPawnValue) }}
                            </span>
                            <span v-else>
                              {{ formatNumberDecimalString(row.tmpNewPawnValue) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.fullValue)
                            }}</td>

                        </tr>
                        <tr v-for="(j, set1) in row.remarkL" :key="set1">
                          <td class="text-sm font-weight-normal text-end" colspan="2">
                            <span style="text-decoration:underline">
                              {{ $t("content.amnassetdetail") }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="6">
                            <span style="color:red">
                              <b>{{ j.val }}</b>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-3 d-flex justify-content-between align-items-center">
                    <material-button color="default" class="float-lg-start float-sm-end mb-3" @click="backToSecondTab">
                      <i class="material-icons material-symbols-outlined">close</i>
                      {{ cancel }}
                    </material-button>

                  </div>

                  <div class="col-9 d-flex justify-content-end align-items-center">

                    <div class="me-3">
                      <material-button color="warning" class="float-lg-start float-sm-end mb-3"
                        :disabled="uid_edit === ''" @click="onCancelDialog">
                        <i class="material-icons material-symbols-outlined">warning</i>
                        {{ cancel }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="capImage" color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="uid_edit === ''" @click="onImgDialog">
                        <i class="material-icons material-symbols-outlined">add_a_photo</i>
                        {{ $t("content.tupload") }}
                      </material-button>
                      <material-button color="primary" variant="outline" class="rounded-circle" data-bs-toggle="modal"
                        data-bs-target="#attachmentsModal" v-show="false" id="attachmenDialog">
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button color="danger" class="float-lg-start float-sm-end mb-3"
                        :disabled="uid_edit === ''" @click="onReturnDialog">
                        <i class="material-icons material-symbols-outlined">reply</i>
                        {{ reject }}
                      </material-button>
                    </div>

                    <div class="me-3">
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="uid_edit === ''" @click="onCompleteDialog">
                        {{ approve }}
                        <i class="material-icons material-symbols-outlined">forward</i>
                      </material-button>
                    </div>
                  </div>
                  <!-- <div class="col-12 d-flex justify-content-end">
                    <div class="me-3">
                      <material-button
                        color="danger"
                        class="float-lg-start float-sm-end mb-3"
                      >
                        <i class="material-icons material-symbols-outlined">reply</i>
                        {{ reject }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button
                        color="primary"
                        class="float-lg-start float-sm-end mb-3"
                      >
                        {{ approve }}
                        <i class="material-icons material-symbols-outlined">forward</i>
                      </material-button>
                    </div>
                  </div> -->
                </div>

              </div>
            </div>
          </div>
        </div>


        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px">{{ $t("content.ccode") }}</th>
                          <th style="width: 180px">{{ $t("content.cnameth") }}</th>
                          <th style="width: 180px">{{ $t("content.cnameen") }}</th>
                          <th style="width: 160px">{{ $t("content.cidcard") }}</th>
                          <th style="width: 110px">{{ $t("content.cmobile") }}</th>
                          <th style="width: 120px">{{ $t("content.cgrade") }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link">1</a></li>
                    <li class="page-item"><a class="page-link">2</a></li>
                    <li class="page-item"><a class="page-link">3</a></li>
                    <li class="page-item">
                      <a class="page-link" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Relation Dialog -->
        <div id="relationPersonalModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px">{{ $t("content.ccode") }}</th>
                          <th style="width: 180px">{{ $t("content.cnameth") }}</th>
                          <th style="width: 180px">{{ $t("content.cnameen") }}</th>
                          <th style="width: 160px">{{ $t("content.cidcard") }}</th>
                          <th style="width: 110px">{{ $t("content.cmobile") }}</th>
                          <th style="width: 120px">{{ $t("content.cgrade") }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link">1</a></li>
                    <li class="page-item"><a class="page-link">2</a></li>
                    <li class="page-item"><a class="page-link">3</a></li>
                    <li class="page-item">
                      <a class="page-link" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <!-- Finger Scan Dialog -->
        <div id="fingerModal" class="modal fade" tabindex="-1" aria-labelledby="fingerModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 500px">
            <div class="modal-content">
              <div class="modal-header justify-content-center">
                <h4 class="modal-title text-center">{{ $t("content.amnfingerscan") }}</h4>
              </div>

              <div class="modal-body">
                <section class="panel">
                  <div class="row">
                    <div class="col-6">
                      <figure class="figure w-100">
                        <figcaption class="figure-caption text-center">
                          <b>Before</b>
                        </figcaption>
                        <img :src="FingerScan" class="img img-fluid figure-img rounded" alt="old" />
                      </figure>
                    </div>
                    <div class="col-6">
                      <figure class="figure w-100">
                        <figcaption class="figure-caption text-center">
                          <b>After</b>
                        </figcaption>
                        <img v-if="datas.scanStep" :src="FingerScan" class="img img-fluid figure-img rounded"
                          alt="old" />
                        <p v-else class="h-100 text-center mb-0 py-3">
                          {{ $t(`content.amnstartscan`) }}
                        </p>
                      </figure>
                    </div>
                  </div>
                  <h5 class="text-center mt-5" :class="datas.scanStep ? `text-success` : `text-danger`">
                    {{
                      datas.scanStep
                        ? $t(`content.amnmatchscan`)
                        : $t(`content.amnnotmatchscan`)
                    }}
                  </h5>
                  <div class="row mt-3">
                    <div class="col-12 text-center">
                      <button type="button" class="btn btn-info btn-lg" @click="datas.scanStep = !datas.scanStep">
                        {{ datas.scanStep ? newscan : startscan }}
                      </button>
                    </div>
                  </div>
                </section>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" :disabled="!datas.scanStep">
                  {{ confirm }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Gallery Modal -->
        <div id="lookupModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 520px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                  @click="visible_g = false">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div id="lookupImages" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators" id="demo1">
                  </div>
                  <div class="carousel-inner" id="demo">
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#lookupImages"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#lookupImages"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- QR Code Modal -->
        <div id="qrcodeModal" class="modal fade" tabindex="-1" aria-labelledby="viewQRcodeModalLabel"
          data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg " style="max-width: 600px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.qrscan") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeqr"
                  @click="paused = true">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>
              <div class="modal-body">

                <div class="row">
                  <div class="col-lg-12">
                    <p class="decode-result">
                      {{ $t("content.orderresult") }} : <b>{{ result }}</b>
                    </p>
                    <p class="error">{{ error }}</p>
                  </div>

                  <div class="col-lg-12">

                    <qrcode-stream :paused="paused" @detect="onDetect" @error="onError"
                      @camera-on="resetValidationState" :constraints="selectedConstraints"
                      :track="trackFunctionSelected.value">
                    </qrcode-stream>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <NewCamera v-if="hideCam" v-model="hideCam" @close="closeCamera" @save="savephotoTaken" />


        <!-- Add File Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="unseizeModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg " style="max-width: 600px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.file") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="d-flex align-items-center mb-1">
                        <!-- <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label> -->
                      </div>
                      <input id="upload" type="file" accept="image/*" @change="addFile" />
                    </div>
                  </div>
                  <pre></pre>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="bank-logo d-flex align-items-center mb-1" v-if="preview !== ''">
                        <img :src="preview" alt="" :width="picWidth" :height="picHeight" aspect-ratio="1" />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="thumb = null">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import ProgressSpinner from 'primevue/progressspinner';
import { QrcodeStream } from 'vue-qrcode-reader'
import InputMask from 'primevue/inputmask';
import { reactive, inject, computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import NewCamera from "../../examples/WebCam/NewCamera.vue";

import FingerScan from "@/assets/img/pawn/finger_scan.png";
import { useWorkItemStore } from '@/stores/workItem'
import { useCategoryStore } from '@/stores/category'
import { useCustomerStore } from '@/stores/customer'
import { useWorkCaseStore } from '@/stores/workcase'
import { useDocumentStore } from '@/stores/document'
import { useTicketImageStore } from '@/stores/ticketimage'
import { usePaymentGroupStore } from '@/stores/paymentgroup'

import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'

const storew = useWorkItemStore()
const storec = useCategoryStore()
const storecus = useCustomerStore()
const storewc = useWorkCaseStore()
const stored = useDocumentStore()
const storeti = useTicketImageStore()
const storepay = usePaymentGroupStore()

const { t } = useI18n();
const swal = inject("$swal");

const selectGroup = ref(false);
const selectType = ref(false);

const images = ref([])

const datas = reactive({
  hide: false,
  searchBox1: "",
  searchBox2: "",
  scanStep: false,
  scanMatch: false,
  demo1: [
    { items: "ธรรมดา" },
    { items: "VIP 1" },
    { items: "VIP 2" },
    { items: "VIP 3" },
    { items: "VIP 4" },
  ],
  demo2: [{ items: "1.75%" }, { items: "1.5%" }, { items: "1%" }, { items: "0.75%" }],
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
});

///// computed
const add = computed(() => t("buttons.addd"));
const save = computed(() => t("buttons.save"));
const cancel = computed(() => t("buttons.canc"));
// const edit = computed(() => t("buttons.edit"));
// const split = computed(() => t("buttons.split"));
const seed = computed(() => t("buttons.seed"));
const confirm = computed(() => t("buttons.conf"));
const startscan = computed(() => t("buttons.sscan"));
const newscan = computed(() => t("buttons.nscan"));
const approve = computed(() => t("buttons.apprv"));
const reject = computed(() => t("buttons.rejec"));

const listQ03 = ref([]);
const listQ03O = ref([]);

const listprint = ref([]);

const listQ03E = ref({});
const listQ03D = ref([]);
const uid_edit = ref('');

const balweight = ref(0);
const balweight_s = ref('');

const balprice = ref(0);
const balprice_s = ref('');

const balfull = ref(0);
const balfull_s = ref('');

const diff = ref(0);
const diff_s = ref('');


const listinfo = ref([]);

const workItem_uid = ref('');
const workItem_version = ref(null);

const visible_g = ref(false)
const images_l = ref([])

const groupuid = ref("");
const typeTrans = ref("");

const dataCloverList = ref([])

const cus_detail = ref({
  nameTh: '',
  nameEn: '',
  birthOrRegDate: new Date(),
  address: {
    address1: "",
    subDistrict: "",
    district: "",
    province: "",
    postcode: "",
    country: ""
  },
  issuedBy: '',
  issuedDate: new Date(),
  expiredDate: new Date(),
  version: '',
  age: 0,
  docTypeUid: '0',
  docNumber: '',
  cusShop: {
    cusTypeUid: "0",
    creditAmount: 0,
    email: "",
    phoneNo1: "",
    phoneNo2: "",
    phoneNo3: "",
  }
})

const workcaseDetail = ref(null);
const interestRate_s = ref('');

const isValid = ref(undefined)
const paused = ref(true)
const result = ref(null)
const error = ref('')

const qr_serach = ref('');
const qr_worckcaseUid = ref('');
const qr_serach2 = ref('');
const qr_worckcaseUid2 = ref('');

const loading = ref(false);
const hideCam = ref(false);
const photoFile = ref(null);

const thumb_id = ref("");
const thumb = ref(null);
const preview = ref("");

const picWidth = ref(400);
const picHeight = ref(400);

const activeTab = ref('tab1');

const cusUid_search = ref('');
const cusName_search = ref('');

const promoint_duration = ref('');
const promoint_val = ref('');


const openCamera = () => {
  hideCam.value = true;
};

const savephotoTaken = async (data) => {
  // console.log('savephotoTaken url', data)
  hideCam.value = false;
  photoFile.value = data
}


const closeCamera = (data) => {
  hideCam.value = false;
};


async function addFile(e) {
  thumb.value = null
  preview.value = ""
  if (e.target.files[0] !== undefined) {
    thumb.value = await toBase64(e.target.files[0])
    preview.value = URL.createObjectURL(e.target.files[0]);
    // thumb_name.value = 'ticket-' + date.getFullYear() + date.getMonth() + 1
    //   + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
    // if (e.target.files[0].type === "image/jpeg") {
    //   thumb_name.value = thumb_name.value + ".jpg"
    // } else if (e.target.files[0].type === "image/png") {
    //   thumb_name.value = thumb_name.value + ".png"
    // }
  }

}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});

const selectedConstraints = ref({ facingMode: 'environment' })

const defaultConstraintOptions = [
  { label: 'rear camera', constraints: { facingMode: 'environment' } },
  { label: 'front camera', constraints: { facingMode: 'user' } }
]
const constraintOptions = ref(defaultConstraintOptions)

/*** track functons ***/

function paintOutline(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

    ctx.strokeStyle = 'red'

    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    for (const { x, y } of otherPoints) {
      ctx.lineTo(x, y)
    }
    ctx.lineTo(firstPoint.x, firstPoint.y)
    ctx.closePath()
    ctx.stroke()
  }
}
function paintBoundingBox(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: { x, y, width, height }
    } = detectedCode

    ctx.lineWidth = 2
    ctx.strokeStyle = '#007bff'
    ctx.strokeRect(x, y, width, height)
  }
}
function paintCenterText(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const { boundingBox, rawValue } = detectedCode

    const centerX = boundingBox.x + boundingBox.width / 2
    const centerY = boundingBox.y + boundingBox.height / 2

    const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width)

    ctx.font = `bold ${fontSize}px sans-serif`
    ctx.textAlign = 'center'

    ctx.lineWidth = 3
    ctx.strokeStyle = '#35495e'
    ctx.strokeText(detectedCode.rawValue, centerX, centerY)

    ctx.fillStyle = '#5cb984'
    ctx.fillText(rawValue, centerX, centerY)
  }
}
const trackFunctionOptions = [
  { text: 'nothing (default)', value: undefined },
  { text: 'outline', value: paintOutline },
  { text: 'centered text', value: paintCenterText },
  { text: 'bounding box', value: paintBoundingBox }
]
const trackFunctionSelected = ref(trackFunctionOptions[1])


const loadDataQ03Api = async (val) => {
  let data = "";
  data = {
    index: '0',
    size: '100',
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storew.listQ03Fetch(payload);


  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        let newL = []
        listd.map(function (x) {
          x.chk = false;
          x.start = null
          x.payside = null
          x.weight_s = ''
          x.quantity_s = ''
          x.newPawnValue_s = ''
          x.fullValue_s = ''
          x.interest_s = ''
          x.trans = null
          return x
        });
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].weight !== null && listd[i].newPawnValue !== null
            && listd[i].fullValue !== null && listd[i].quantity !== null && listd[i].roundInterest !== null
            && listd[i].totalPay !== null && listd[i].totalReceive !== null) {
            // console.log("if 1 " + i + ' : ' + JSON.stringify(listd[i]));
            listd[i].start = format_date(new Date(listd[i].startTime))
            listd[i].payside = checkPaySide(listd[i].paySide)
            listd[i].weight_s = formatNumberDecimalString(listd[i].weight)
            listd[i].quantity_s = formatNumberDecimalString(listd[i].quantity)
            listd[i].newPawnValue_s = formatNumberDecimalString(listd[i].newPawnValue)
            listd[i].fullValue_s = formatNumberDecimalString(listd[i].fullValue)
            listd[i].interest_s = formatNumberDecimalString(listd[i].roundInterest)
            listd[i].trans = checkTransaction(listd[i].type)
            newL.push(listd[i])
          } else {
            // console.log("qo3 null  : " + JSON.stringify(listd[i]));
            // console.log("qo3 start " + [i] + ' : ' + format_date(new Date(listd[i].startTime)) + ' workcase ' + listd[i].workCaseUid);
          }
        }
        listQ03.value = newL
      }
    }
  }
}


const loadDataQ03OwnerApi = async (val) => {
  let data = "";
  data = {
    index: '0',
    size: '100',
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storew.listQ03OwnerFetch(payload);
  // console.log("response : " + JSON.stringify(response.data));

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        let newL = []
        listd.map(function (x) {
          x.chk = false;
          x.start = null
          x.payside = null
          x.weight_s = ''
          x.quantity_s = ''
          x.newPawnValue_s = ''
          x.fullValue_s = ''
          x.interest_s = ''
          x.trans = null
          return x
        });
        for (let i = 0; i < listd.length; i++) {
          if (listd[i].weight !== null && listd[i].newPawnValue !== null
            && listd[i].fullValue !== null && listd[i].quantity !== null && listd[i].roundInterest !== null
            && listd[i].totalPay !== null && listd[i].totalReceive !== null) {
            // console.log("if 2 " + i + ' : ' + JSON.stringify(listd[i]));
            listd[i].start = format_date(new Date(listd[i].startTime))
            listd[i].payside = checkPaySide(listd[i].paySide)
            listd[i].weight_s = formatNumberDecimalString(listd[i].weight)
            listd[i].quantity_s = formatNumberDecimalString(listd[i].quantity)
            listd[i].newPawnValue_s = formatNumberDecimalString(listd[i].newPawnValue)
            listd[i].fullValue_s = formatNumberDecimalString(listd[i].fullValue)
            listd[i].interest_s = formatNumberDecimalString(listd[i].roundInterest)
            listd[i].trans = checkTransaction(listd[i].type)
            newL.push(listd[i])
          } else {
            // console.log("qo3 null  : " + JSON.stringify(listd[i]));
            // console.log("qo3 start " + [i] + ' : ' + format_date(new Date(listd[i].startTime)) + ' workcase ' + listd[i].workCaseUid);
          }
        }
        listQ03O.value = newL
      }
    }
  }
}



loadDataQ03Api()


const loadDataPrintApi = async () => {
  // await new Promise((res) => setTimeout(res, 500));
  let data = "";
  data = {
    uid: "",
  };
  const payload = data;
  // const response = await store.dispatch("categorylistPrintFetch", payload);
  const response = await storec.categorylistPrintFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listprint.value = response.data.data;
        // console.log('Listdata : ' + JSON.stringify(listprint.value))
      }
    }
  }
};

// loadDataPrintApi();

async function onClickTab(val) {
  if (val === '1') {
    activeTab.value = 'tab1';
    loadDataQ03Api()
    await setfirstTab()
  } else if (val === '2') {
    activeTab.value = 'tab2';
    loadDataQ03OwnerApi()
    await setsecondTab()
  } else if (val === '3') {
    activeTab.value = 'tab3';
    await setthirdTab()
  }
}

async function onSelectGroup() {
  // console.log('onSelectGroup : ' + selectGroup.value)
  if (!selectGroup.value) {
    groupuid.value = ''
    for (let i = 0; i < listQ03.value.length; i++) {
      listQ03.value[i].chk = false
    }
  }
}

async function onSelectType() {
  // console.log('onSelectType : ' + selectType.value)
  if (!selectType.value) {
    typeTrans.value = ''
    for (let i = 0; i < listQ03.value.length; i++) {
      listQ03.value[i].chk = false
    }
  }
}

async function onSelectListQ02(item) {

  if (selectGroup.value || selectType.value) {
    if (selectGroup.value) {

      if (item.chk) {
        groupuid.value = item.paymentGroupUid
      } else {
        groupuid.value = ''
      }

      for (let i = 0; i < listQ03.value.length; i++) {
        if (listQ03.value[i].paymentGroupUid === item.paymentGroupUid) {
          listQ03.value[i].chk = item.chk
        }
      }
    } else if (selectType.value) {

      if (item.chk) {
        typeTrans.value = item.type

      } else {
        typeTrans.value = ''
      }

      for (let i = 0; i < listQ03.value.length; i++) {
        if (listQ03.value[i].type === item.type) {
          //   console.log("typeTrans : " + typeTrans.value + ' type : ' + listQ02.value[i].type + " chk : " + item.chk)
          listQ03.value[i].chk = item.chk
        }
      }
    }
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function filterCanPrint(items) {
  const result = dataCloverList.value.some(item =>
    item.uid === items.uid
  )
  return result
}


function delCanPrint(items) {
  if (filterCanPrint(items)) {
    let filteredArr = dataCloverList.value.filter(e => e.uid !== items.uid)
    dataCloverList.value = filteredArr
    // console.log('delCanPrint : ' + JSON.stringify(dataCloverList.value));
  }

}



function filterCat(str) {
  let side = ''
  let name = listprint.value.filter((c) => c.uid === str);
  if (name.length > 0) {
    side = name[0].name
  }
  return side
}



function checkPaySide(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE

  let side = ''
  if (str === 'PAY') {
    side = 'จ่ายเงิน'
  } else if (str === 'RECEIVE') {
    side = 'รับเงิน'
  } else if (str === 'NONE') {
    side = 'ไม่มียอด'
  }
  return side
}


function format_date(value) {
  if (value) {
    // return moment.utc(String(value)).local().format('DD/MM/YYYY HH:mm')
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}

function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  } else if (str === 'REVIEW_COLLATERAL') {
    side = 'ขอดูทรัพย์'
  }
  return side
}

function canEdit(item) {
  let bool = true
  if ((item.type === 'INC_PRINCIPLE' && item.step.code === 'S050') || item.step.code === 'S030') {
    bool = false
  }
  return bool
}

function canKeep(item) {
  let bool = false

  if (item.type === 'REDEEM' || item.step.code === 'S030') {
    bool = true
  }

  if (bool === false) {
    if (item.type === 'INC_PRINCIPLE' && item.step.code === 'S050') {
      bool = true
    }
  }

  return bool
}

function canCancel(item) {
  let bool = false

  if (item.type === 'REDEEM' || item.step.code === 'S030' || item.type === 'REVIEW_COLLATERAL') {
    bool = true
  }

  if (bool === false) {
    if (item.type === 'INC_PRINCIPLE') {
      if (item.step.code === 'S050' || item.step.code === 'S061' || item.step.code === 'S062') {
        bool = true
      }
    }
  }

  if (bool === false) {
    if (item.type === 'DEC_PRINCIPLE') {
      if (item.step.code === 'S061') {
        bool = true
      }
    }
  }

  return bool
}

function canPrint(item) {
  let bool = false
  if (item.type === 'REDEEM' || item.step.code === 'S030' || item.type === 'REVIEW_COLLATERAL') {
    bool = true
  }

  if (bool === false) {
    if (item.type === 'INC_PRINCIPLE') {
      if (item.step.code === 'S050' || item.step.code === 'S061' || item.step.code === 'S062') {
        bool = true
      }
    }
  }

  if (bool === false) {
    if (item.type === 'DEC_PRINCIPLE') {
      if (item.step.code === 'S051') {
        bool = true
      }
    }
  }


  return bool
}


async function chkSelect() {
  let process = false
  let lockL = []
  for (let i = 0; i < listQ03.value.length; i++) {
    if (listQ03.value[i].chk) {
      process = true
      // console.log('chkSelect u : ' + JSON.stringify(listQ02.value[i].uid) + ' v : ' + JSON.stringify(listQ02.value[i].version));
      lockL.push({ uid: listQ03.value[i].uid, version: listQ03.value[i].version })

    }
  }

  if (!process) {
    swal("กรุณาเลือกรายการ", "", "error");
  } else {
    // console.log('lockL : ' + lockL.length);
    await lock(lockL, '')
  }

}

async function onOpenQRModal() {
  qr_serach.value = ''
  qr_worckcaseUid.value = ''
  paused.value = false
  await resetValidationState()
  document.getElementById("qrModalShow").click();
}

/*** error handling ***/
function onError(err) {
  error.value = `[${err.name}]: `
  if (err.name === 'NotAllowedError') {
    error.value += 'you need to grant camera access permission'
  } else if (err.name === 'NotFoundError') {
    error.value += 'no camera on this device'
  } else if (err.name === 'NotSupportedError') {
    error.value += 'secure context required (HTTPS, localhost)'
  } else if (err.name === 'NotReadableError') {
    error.value += 'is the camera already in use?'
  } else if (err.name === 'OverconstrainedError') {
    error.value += 'installed cameras are not suitable'
  } else if (err.name === 'StreamApiNotSupportedError') {
    error.value += 'Stream API is not supported in this browser'
  } else if (err.name === 'InsecureContextError') {
    error.value +=
      'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
  } else {
    error.value += err.message
  }

  // document.getElementById("closeqr").click();
  // paused.value = false
  // resetValidationState()
}

async function resetValidationState() {
  isValid.value = undefined
  result.value = ''
}


async function onDetect([firstDetectedCode]) {
  result.value = firstDetectedCode.rawValue
  paused.value = true
  // pretend it's taking really long
  await timeout(2000)
  isValid.value = result.value
  if (activeTab.value === '1') {
    qr_serach.value = result.value
    setResultQRTab1()
  } else if (activeTab.value === '2') {
    qr_serach2.value = result.value
    setResultQRTab2()
  }

  await resetValidationState()
  document.getElementById("closeqr").click();
  // some more delay, so users have time to read the message

}

function timeout(ms) {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms)
  })
}


async function setResultQRTab1() {

  if (qr_serach.value.trim() !== '' && listQ03.value.length > 0) {
    let lw = []
    if (qr_serach.value.length > 2) {
      // console.log("search tkRef : " + qr_serach.value);
      lw = listQ03.value.filter((c) => c.initRefNumber === qr_serach.value);
    } else {
      // console.log("search trayId : " + qr_serach.value);
      lw = listQ03.value.filter((c) => c.trayId === qr_serach.value);
    }
    if (lw.length > 0) {
      // console.log('lw : ' + JSON.stringify(lw[0]))
      qr_worckcaseUid.value = lw[0].workCaseUid
      let lockL = []
      lockL.push({ uid: lw[0].uid, version: lw[0].version })
      await lock(lockL, 'auto')
    } else {
      swal("ไม่พบข้อมูล", "", "error");
    }
  }

}

async function setResultQRTab2() {
  // console.log('active 2 : ' + activeTab.value)
  if (qr_serach2.value.trim() !== '' && listQ03O.value.length > 0) {
    let lw = []
    if (qr_serach2.value.length > 2) {
      // console.log("search tkRef 2 : " + qr_serach.value);
      lw = listQ03O.value.filter((c) => c.initRefNumber === qr_serach2.value || c.refNumber === qr_serach2.value);
    } else {
      // console.log("search trayId 2 : " + qr_serach.value);
      lw = listQ03O.value.filter((c) => c.trayId === qr_serach2.value);
    }
    if (lw.length > 0) {
      if (!canEdit(lw[0])) {
        qr_worckcaseUid2.value = lw[0].workCaseUid
        openTab(lw[0])
        qr_serach2.value = ''
        qr_worckcaseUid2.value = ''
      } else if (!canKeep(lw[0])) {
        qr_worckcaseUid2.value = lw[0].workCaseUid
        onKeep(lw[0])
        qr_serach2.value = ''
        qr_worckcaseUid2.value = ''
      }
    } else {
      swal("ไม่พบข้อมูล", "", "error");
    }
  }
}

async function findQ03Owner() {
  await loadDataQ03OwnerApi()
  if (listQ03O.value.length > 0) {
    let lw = []
    lw = listQ03O.value.filter((c) => c.workCaseUid === qr_worckcaseUid.value);
    if (lw.length > 0) {
      openTab(lw[0])
      qr_serach.value = ''
      qr_worckcaseUid.value = ''
    }
  }
}


async function clearItemCustomerInfo() {
  cus_detail.value = {
    nameTh: '',
    nameEn: '',
    birthOrRegDate: new Date(),
    address: {
      address1: "",
      subDistrict: "",
      district: "",
      province: "",
      postcode: "",
      country: ""
    },
    issuedDate: new Date(),
    expiredDate: new Date(),
    version: '',
    age: 0,
    docTypeUid: '0',
    docNumber: '',
    cusShop: {
      cusTypeUid: "0",
      creditAmount: 0,
    }
  }
}

async function setCustomerInfo(item) {
  await getCustomerInfo(item.customerUid)
  if (cus_detail.value.phoneNo1 === null) {
    cus_detail.value.phoneNo1 = ""
  }
}

async function getCustomerInfo(uid) {

  let data = "";
  data = {
    uid: uid,
  };

  const payload = data;
  const response = await storecus.customerInfoFetch(payload);
  // console.log("getCustomerInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        cus_detail.value = response.data.data
        // console.log("getCustomerInfo : " + JSON.stringify(cus_detail.value));
        // listcusbank.value = response.data.data.list;
      }
    }
  }
}


async function openTab(item) {
  // console.log('type : ' + JSON.stringify(item.type));
  if (listprint.value.length === 0) {
    await loadDataPrintApi()
  }
  workItem_uid.value = item.uid
  workItem_version.value = item.version
  images.value = []
  images_l.value = []
  listinfo.value = []
  workcaseDetail.value = null
  interestRate_s.value = ''
  cusUid_search.value = ''
  cusName_search.value = ''
  promoint_duration.value = ''
  promoint_val.value = ''
  clearItemCustomerInfo()
  await getworkCaseInfo(item.workCaseUid)




  if (workcaseDetail.value != null) {
    // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
    await setCustomerInfo(workcaseDetail.value.customerInfo)
    listQ03E.value = await item
    images_l.value = workcaseDetail.value.ticket.images
    uid_edit.value = workcaseDetail.value.ticket.uid
    listinfo.value = workcaseDetail.value.ticket.collateralInfo
    cusUid_search.value = workcaseDetail.value.ticket.relatedCusUid
    cusName_search.value = workcaseDetail.value.ticket.relatedCusName
    diff.value = workcaseDetail.value.diffValue;
    diff_s.value = formatNumberString(diff.value);

    interestRate_s.value = formatNumberDecimalString(workcaseDetail.value.interestPerMonth)
    if (workcaseDetail.value.interestPromotion === null || workcaseDetail.value.interestPromotion === 0) {
      promoint_val.value = formatNumberDecimalString(0)
    } else {
      promoint_val.value = formatNumberDecimalString(workcaseDetail.value.interestPromotion)
    }
    if (workcaseDetail.value.promotionDuration === 0) {
      promoint_duration.value = '-'
    } else {
      promoint_duration.value = workcaseDetail.value.promotionDuration + ' เดือน'
    }

    if (item.type === 'REDEEM') {
      item.newPawnValue = workcaseDetail.value.newPawnValue
      item.newPawnValue_s = formatNumberDecimalString(item.newPawnValue)
      balprice.value = workcaseDetail.value.pawnValue;
    } else {
      balprice.value = workcaseDetail.value.newPawnValue;
    }

    listQ03D.value = workcaseDetail.value.ticket.collaterals
    listQ03D.value.map(function (x) {
      x.chk = false
      x.remarkL = []
      return x
    });
    // console.log('uid_edit : ' + uid_edit.value);
  }
  // console.log('list : ' + JSON.stringify(listQ03D.value));

  balweight.value = item.weight;
  balfull.value = item.fullValue;



  if (listQ03D.value.length > 0) {
    for (let i = 0; i < listQ03D.value.length; i++) {
      balweight.value = balweight.value - listQ03D.value[i].weight
      balfull.value = balfull.value - listQ03D.value[i].fullValue
      listQ03D.value[i].remarkL.push({ val: listQ03D.value[i].remark })
      if (item.type === 'INC_PRINCIPLE' || item.type === 'DEC_PRINCIPLE') {
        balprice.value = balprice.value - listQ03D.value[i].tmpNewPawnValue
      } else {
        balprice.value = balprice.value - listQ03D.value[i].newPawnValue
      }

    }
  }

  if (balweight.value !== null) {
    balweight.value = balweight.value.toFixed(2)
  }

  if (balprice.value !== null) {
    balprice.value = balprice.value.toFixed(2)
  }

  if (balfull.value !== null) {
    balfull.value = balfull.value.toFixed(2)
  }

  balweight_s.value = formatNumberString(balweight.value);
  balprice_s.value = formatNumberString(balprice.value);
  balfull_s.value = formatNumberString(balfull.value);

  await setthirdTab()
}

async function getworkCaseInfo(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;

  const response = await storewc.workCaseinfoFetch(payload);
  // console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value = response.data.data
      }
    }
  }
}




async function setfirstTab() {

  if (document.getElementById("second-home-tab")) {
    document.getElementById("second-home-tab").classList.remove('show', 'active')
    // console.log("found 2 !! ");
  }

  if (document.getElementById("secondHome")) {
    document.getElementById("secondHome").classList.remove('show', 'active')
    // console.log("found 2x !! ");
  }

  if (document.getElementById("third-home-tab")) {
    document.getElementById("third-home-tab").classList.remove('show', 'active')
    // console.log("found 3 !! ");
  }

  if (document.getElementById("thirdHome")) {
    document.getElementById("thirdHome").classList.remove('show', 'active')
    // console.log("found 3x !! ");
  }

  if (document.getElementById("first-home-tab")) {
    document.getElementById("first-home-tab").classList.add('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstHome")) {
    document.getElementById("firstHome").classList.add('show', 'active')
    // console.log("found 1x !! ");
  }

}


async function setsecondTab() {

  if (document.getElementById("first-home-tab")) {
    document.getElementById("first-home-tab").classList.remove('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstHome")) {
    document.getElementById("firstHome").classList.remove('show', 'active')
    // console.log("found 1x !! ");
  }

  if (document.getElementById("third-home-tab")) {
    document.getElementById("third-home-tab").classList.remove('show', 'active')
    // console.log("found 3 !! ");
  }

  if (document.getElementById("thirdHome")) {
    document.getElementById("thirdHome").classList.remove('show', 'active')
    // console.log("found 3x !! ");
  }

  if (document.getElementById("second-home-tab")) {
    document.getElementById("second-home-tab").classList.add('show', 'active')
    // console.log("found 2 !! ");
  }

  if (document.getElementById("secondHome")) {
    document.getElementById("secondHome").classList.add('show', 'active')
    // console.log("found 2x !! ");
  }
}

async function setthirdTab() {

  if (document.getElementById("first-home-tab")) {
    document.getElementById("first-home-tab").classList.remove('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstHome")) {
    document.getElementById("firstHome").classList.remove('show', 'active')
    // console.log("found 1x !! ");
  }

  if (document.getElementById("second-home-tab")) {
    document.getElementById("second-home-tab").classList.remove('show', 'active')
    // console.log("found 2 !! ");
  }

  if (document.getElementById("secondHome")) {
    document.getElementById("secondHome").classList.remove('show', 'active')
    // console.log("found 2x !! ");
  }

  if (document.getElementById("third-home-tab")) {
    document.getElementById("third-home-tab").classList.add('show', 'active')
    // console.log("found 3 !! ");
  }

  if (document.getElementById("thirdHome")) {
    document.getElementById("thirdHome").classList.add('show', 'active')
    // console.log("found 3x !! ");
  }

}

async function backToFirstTab() {
  uid_edit.value = ''
  workItem_uid.value = ''
  workItem_version.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  listQ03D.value = []
  balweight.value = 0;
  balprice.value = 0;
  balfull.value = 0;
  balweight_s.value = ''
  balprice_s.value = ''
  balfull_s.value = ''
  listQ03E.value = {}
  diff.value = 0
  diff_s.value = ''
  workcaseDetail.value = null
  interestRate_s.value = ''
  promoint_duration.value = ''
  promoint_val.value = ''
  clearItemCustomerInfo()
  await onClickTab('2')
}


async function backToSecondTab() {
  uid_edit.value = ''
  workItem_uid.value = ''
  workItem_version.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  listQ03D.value = []
  balweight.value = 0;
  balprice.value = 0;
  balfull.value = 0;
  balweight_s.value = ''
  balprice_s.value = ''
  balfull_s.value = ''
  listQ03E.value = {}
  diff.value = 0
  diff_s.value = ''
  workcaseDetail.value = null
  interestRate_s.value = ''
  promoint_duration.value = ''
  promoint_val.value = ''
  clearItemCustomerInfo()
  await onClickTab('2')
}


async function openGallery() {

  if (images.value.length === 0) {
    loading.value = true
    let url = []
    images.value = []
    for (let i = 0; i < images_l.value.length; i++) {
      // console.log("logoUid : " + item.images[i].imageUid);
      if (images_l.value[i].imageUid !== "0") {
        url.push(String(st.url_api + "/doc-svc/document/info/" + images_l.value[i].imageUid));
        // urls.value.push(axios.get((st.url_document + "/doc-svc/document/info/" + lb[i].logoUid)));
      }
    }

    if (url.length > 0) {
      await getFile(url)
      if (images.value.length > 0) {
        setGallery()
        loading.value = false
        document.getElementById("myModalClose").click();
      }
    }

  } else {
    setGallery()
    loading.value = false
    document.getElementById("myModalClose").click();
  }


}



async function onReturnDialog() {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการตีกลับรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      onReturn()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}


async function onCompleteDialog() {
  swal({
    title: "ตรวจสอบรายการ",
    text: "คุณต้องการอนุมัติรายการนี้",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onComplete()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

async function onKeepDialog(item) {
  swal({
    title: "บันทึกรายการ",
    text: "คุณต้องการเก็บทรัพย์รายการนี้",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onKeep(item)
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

async function onCancelDialog() {
  swal({
    title: "ยกเลิกรายการ",
    text: "คุณต้องการยกเลิกรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onCancel()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

async function onUnLockDialog(item) {
  swal({
    title: "ปลดล็อกรายการ",
    text: "คุณต้องการปลดล็อกรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onUnLock(item)
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

async function onCancelTicketDialog(item) {
  swal({
    title: "ยกเลิกตั๋ว",
    text: "คุณต้องการยกเลิกตั๋วรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onCancelTicket(item)
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}

async function onReturn() {
  // console.log('onReturn : ' + workItem_uid.value + " version : " + workItem_version.value);
  let obj = {
    uid: workItem_uid.value,
    action: 'return',
    version: workItem_version.value,
    process: 'return'
  }
  await completeStep(obj)
}

async function onComplete() {
  // console.log('onComplete : ' + workItem_uid.value + " version : " + workItem_version.value);
  let obj = {
    uid: workItem_uid.value,
    action: 'complete',
    version: workItem_version.value,
    process: 'complete'
  }
  if (photoFile.value !== null || thumb.value !== null) {
    await addfileApi()
  }
  await completeStep(obj)
}


async function onKeep(item) {
  // console.log('onKeep : ' + JSON.stringify(item.uid) + " version : " + JSON.stringify(item.version));
  let obj = {
    uid: item.uid,
    action: 'complete',
    version: item.version,
    process: 'keep'
  }
  await completeStep(obj)
  loadDataQ03OwnerApi();
}


async function onCancelTicket(item) {
  // console.log('onCancelTicket : ' + JSON.stringify(item.uid) + " version : " + JSON.stringify(item.version));
  let obj = {
    uid: item.uid,
    action: 'cancel_ticket',
    version: item.version,
    process: 'cancel_ticket'
  }
  await completeStep(obj)
  loadDataQ03OwnerApi();
}

async function onCancel() {
  // console.log('onCancel : ' + workItem_uid.value + " version : " + workItem_version.value);
  let obj = {
    uid: workItem_uid.value,
    action: 'cancel',
    version: workItem_version.value,
    process: 'cancel'
  }
  await completeStep(obj)
}

async function onUnLock(item) {
  // console.log('onUnLock : ' + JSON.stringify(item.uid) + " version : " + JSON.stringify(item.version));
  let lockL = []
  lockL.push({ uid: item.uid, version: item.version })
  await unlock(lockL)
}

async function completeStep(item) {
  let data = "";
  data = {
    uid: item.uid,
    action: item.action,
    version: item.version,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);
  const response = await storew.completeStepFetch(payload);
  // console.log('completeStep : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        if (item.process !== 'keep' && item.process !== 'cancel_ticket') {
          if (item.process === 'complete' && listQ03E.value.type !== 'REDEEM') {
            await ticketGen(listQ03E.value)
          }
          await backToFirstTab()
        } else {
          delCanPrint(item)
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function lock(item, val) {
  let data = "";
  data = {
    lock: item,
  };
  const payload = data;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storew.lockFetch(payload);
  // console.log('response : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        selectGroup.value = false
        selectType.value = false
        loadDataQ03Api()
        if (val === 'auto') {
          swal({
            title: "บันทึกสำเร็จ",
            text: "",
            type: "success",
            timer: 1000
          }).then(() => {
            findQ03Owner()
          })
        } else {
          swal("บันทึกสำเร็จ", "", "success");
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function unlock(item) {
  let data = "";
  data = {
    lock: item,
  };
  const payload = data;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storew.unlockFetch(payload);
  // console.log('response : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        if (workItem_uid.value !== '' && workItem_uid.value === item[0].uid) {
          backToSecondTab()
        } else {
          loadDataQ03OwnerApi()
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function ticketGen(item) {
  await getticket(item.workCaseUid)
}

// async function backCoverGen(item) {
//   await getbackcover(item.workCaseUid)
// }

async function getticket(id) {
  // console.log('id : ' + id)
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/ticket/` + id, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    // console.log('url : ' + url)
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}

// async function getbackcover(id) {
//   const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
//   const accessToken = key.accessToken;
//   const response = await axios.get(st.url_api + `/docgen-svc/backCover/` + id, {
//     headers: { Authorization: `Bearer ${accessToken}` },
//     responseType: 'arraybuffer'
//   });

//   if (response) {
//     console.log('blob - response ', response);
//     const blob = new Blob([response.data], { type: "application/pdf" });
//     const url = URL.createObjectURL(blob);
//     // console.log('url : ' + url)
//     window.open(url, '_blank').focus();
//   } else {
//     swal("โหลด PDF ไม่สำเร็จ", "", "error");
//   }
// }

function setGallery() {

  var x = "", i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item active"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo1").innerHTML = y;
  document.getElementById("demo").innerHTML = x;

}

async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: ''
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}


function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  // const trimmedString = string64;
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  var ext = fileName.split('.').pop();
  let type = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}

async function addfileApi() {
  let fileInput = null
  if (photoFile.value !== null) {
    fileInput = photoFile.value
  } else if (thumb.value !== null) {
    fileInput = thumb.value
  }
  let data = "";
  let date = new Date()
  let filename = 'ticketimg-' + date.getFullYear() + date.getMonth() + 1
    + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds();
  data = {
    name: filename,
    data: fileInput,
  };

  const payload = data;
  // console.log('addfileApi : ' + JSON.stringify(payload));
  // const response = await store.dispatch("addFetch", payload);
  const response = await stored.addFetch(payload);
  // console.log('addfileApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
        // console.log('thumb_id : ' + thumb_id.value);
        addTicketImage()
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", "", "error");
      }
    }
  }
}


async function addTicketImage() {
  let data = "";
  data = {
    ticketUid: uid_edit.value, // always required
    uid: "0",
    imageUid: thumb_id.value,
    remark: "",
    version: "0"
  };

  const payload = data;
  const response = await storeti.ticketImageNewFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // swal("บันทึกสำเร็จ", "", "success");
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function onImgDialog() {
  swal({
    title: "",
    text: "คุณต้องการเพิ่มภาพจาก",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
    input: 'select',
    inputOptions: {
      'Camera': 'ถ่ายภาพ',
      'File': 'แนบไฟล์ภาพถ่าย',
    },
    inputPlaceholder: 'กรุณาเลือก',
    inputValidator: function (value) {
      return new Promise(function (resolve, reject) {
        if (value === 'Camera') {
          resolve()
          thumb.value = null
          preview.value = ''
          photoFile.value = null
          openCamera()
        } else if (value === 'File') {
          thumb.value = null
          preview.value = ''
          photoFile.value = null
          document.getElementById("upload").value = null;
          resolve()
          document.getElementById("attachmenDialog").click();
        } else {
          resolve("กรุณาเลือกรายการ :)");
        }
      })
    }
  });
}

async function getPaymentGroupInfo() {
  let data = "";
  data = {
    uid: workcaseDetail.value.paymentGroupUid,
  }
  const payload = data;
  const response = await storepay.paymentGroupinfoFetch(payload);
  // console.log('getPaymentGroupInfo r : ' + JSON.stringify(response.data.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {

        // console.log('paymentGDetail : ' + JSON.stringify(paymentGDetail.value.version));
        // let list = paymentGDetail.value.workCases
        // for (let i = 0; i < list.length; i++) {
        //   if (list[i].ticket !== null && list[i].transactionType === 'PAWN') {
        //     let objR = {
        //       ticketUid: list[i].ticket.uid,
        //       relatedCusUid: cusUid_search.value,
        //       relatedCusName: cusName_search.value,
        //       version: list[i].ticket.version,
        //     }
        //     console.log('tuid : ' + uid_edit.value + ' objR : ' + JSON.stringify(objR));
        //     // await updateTicketRelatedCus(objR)
        //   }
        // }
        // console.log('paymentGDetail : ' + JSON.stringify(paymentGDetail.value.workCases[0].ticket));

      } else {
        // swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }

}

onMounted(() => {
  if (document.getElementById("choices-gender")) {
    var gender = document.getElementById("choices-gender");
    new Choices(gender);
  }

  if (document.getElementById("choices-language")) {
    var language = document.getElementById("choices-language");
    new Choices(language);
  }

  if (document.getElementById("choices-month")) {
    var month = document.getElementById("choices-month");
    setTimeout(function () {
      new Choices(month);
    }, 1);

    // eslint-disable-next-line no-unused-vars
    var d = new Date();
    var monthArray = new Array();
    monthArray[0] = "January";
    monthArray[1] = "February";
    monthArray[2] = "March";
    monthArray[3] = "April";
    monthArray[4] = "May";
    monthArray[5] = "June";
    monthArray[6] = "July";
    monthArray[7] = "August";
    monthArray[8] = "September";
    monthArray[9] = "October";
    monthArray[10] = "November";
    monthArray[11] = "December";
    for (let m = 0; m <= 11; m++) {
      var optn = document.createElement("OPTION");
      optn.text = monthArray[m];
      // server side month start from one\
      optn.value = m + 1;
      // if june selected
      if (m == 1) {
        optn.selected = true;
      }
      month.options.add(optn);
    }
  }

  if (document.getElementById("choices-day")) {
    var day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day);
    }, 1);

    for (let y = 1; y <= 31; y++) {
      var optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }

  if (document.getElementById("choices-year")) {
    var year = document.getElementById("choices-year");
    setTimeout(function () {
      new Choices(year);
    }, 1);

    for (let y = 1900; y <= 2020; y++) {
      var optn2 = document.createElement("OPTION");
      optn2.text = y;
      optn2.value = y;

      if (y == 2020) {
        optn.selected = true;
      }

      year.options.add(optn2);
    }
  }

  if (document.getElementById("choices-skills")) {
    var skills = document.getElementById("choices-skills");
    new Choices(skills, {
      delimiter: ",",
      editItems: true,
      maxItemCount: 5,
      removeItemButton: true,
      addItems: true,
    });
  }
  if (document.getElementById("first-home-tab")) {
    document.getElementById("first-home-tab").classList.add('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstHome")) {
    document.getElementById("firstHome").classList.add('show', 'active')
    // console.log("found 1x !! ");
  }
});
</script>

<style lang="scss" scoped>
.review {
  border: 2px solid #1a73e8;
  border-radius: 6px;
  background: #d9e9ff;
}

.left {
  display: flex;
  justify-content: flex-end;
  margin-right: auto;
  margin-left: 0;
}

.inline-icon {
  display: inline-flex;
  vertical-align: top;
  font-size: 28px !important;
}

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
