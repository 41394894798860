<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <ul id="financeTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="first-finance" class="nav-link" data-bs-toggle="tab" data-bs-target="#firstFinance"
              type="button" role="tab" aria-controls="firstFinance" aria-selected="true" @click="onClickTab('1')">
              {{ $t("content.amnrecheckfinance") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-finance" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondFinance"
              type="button" role="tab" aria-controls="secondFinance" aria-selected="false" @click="onClickTab('2')">
              {{ $t("content.amnalljobs") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="third-finance" class="nav-link" data-bs-toggle="tab" data-bs-target="#thirdFinance"
              type="button" role="tab" aria-controls="thirdFinance" aria-selected="false"
              :disabled="version_edit === ''" @click="onClickTab('3')">
              {{ $t("content.amnconfirmfinance") }}
            </button>
          </li>
        </ul>

        <div id="financeTabContent" class="tab-content">
          <div id="firstFinance" class="tab-pane fade" role="tabpanel" aria-labelledby="first-finance">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnrecheckfinance") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-10">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusName_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>



                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                      @click="loadDataQ02Api">
                      <i class="material-icons material-symbols-outlined">search</i>
                      {{ $t("buttons.sear") }}
                    </material-button>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.jobId") }}</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnanalysvalnew") }}</th>
                          <th>{{ $t("content.amnanalysvalreal") }}</th>
                          <th>{{ $t("content.statuspay") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                        </tr>
                      </thead>

                      <tbody v-for="(i, pay1) in listQ02" :key="pay1" :data-group-index="pay1">
                        <tr class="group-id-ticket" :class="i.status === 'Finish' ? '' : 'bg-working'">
                          <td class="text-sm font-weight-normal">
                            <div class="group-tickect" style="padding: 0.5rem !important">
                              <material-checkbox v-model="i.chk" :checked="i.chk" :disabled="i.status !== 'Finish'">
                              </material-checkbox>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="8">
                            <div class="group-tickect" style="padding: 0.5rem !important">
                              <h6 class="mb-0">
                                <b>{{ t("content.amngroupticket") }} #{{ pay1 + 1 }} ({{ $t("content.jobId") }}:
                                  {{ i.jobId }}) -
                                  {{ i.customerName }} ({{ $t("content.amntotalval") }} : {{
                                    formatNumberString(i.roundTotalPay) }}
                                  {{ $t("content.curency") }})
                                </b>
                              </h6>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="white" class="float-lg-start float-sm-end mb-0"
                              @click="toggleRowQ02(pay1)">
                              <i v-if="i.tg" class="material-icons material-symbols-outlined">expand_less</i>
                              <i v-else class="material-icons material-symbols-outlined">expand_more</i>
                            </material-button>
                          </td>
                        </tr>
                        <template v-if="i.tg">
                          <tr v-for="(j, set1) in i.items" :key="set1">
                            <template v-if="j.ticket !== null">
                              <td class="text-sm font-weight-normal">{{ set1 + 1 }}</td>
                              <td class="text-sm font-weight-normal">{{ j.jobId }}</td>
                              <td class="text-sm font-weight-normal">{{ i.start }}
                              </td>
                              <td class="text-sm font-weight-normal">
                                {{ j.tkRefNumber }}
                              </td>
                              <td class="text-sm font-weight-normal">
                                {{ checkTransaction(j.type) }}
                              </td>
                              <td class="text-sm font-weight-normal" :class="j.totalReceive > 0
                                ? `text-success`
                                : j.totalPay > 0 ? `text-danger` : ''
                                ">
                                <span v-if="j.migration">
                                  <span
                                    v-if="j.migrationType === 'PAWN' || j.migrationType === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                                <span v-else>
                                  <span v-if="j.type === 'PAWN' || j.type === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal" :class="j.totalReceive > 0
                                ? `text-success`
                                : j.totalPay > 0 ? `text-danger` : ''
                                ">
                                <span v-if="j.migration">
                                  <span
                                    v-if="j.migrationType === 'PAWN' || j.migrationType === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                                <span v-else>
                                  <span v-if="j.type === 'PAWN' || j.type === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <span v-if="j.status !== 'TERMINATE'">
                                  <span v-if="j.waitForCondition"> {{ $t("content.amnstateacting") }}</span>
                                  <span v-if="!j.waitForCondition"> {{ $t("content.amnstateending") }}</span>
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td class=" text-sm font-weight-normal text-center">
                                {{ checkProcess(j.status) }}
                              </td>
                              <td class="text-sm font-weight-normal">{{ i.latestUserName }}</td>
                            </template>

                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12 d-flex justify-content-end">
                    <div class="me-3">
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="chkSelect">
                        <i class="material-icons material-symbols-outlined">lock</i>
                        {{ $t("content.amnselectlist") }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondFinance" class="tab-pane fade" role="tabpanel" aria-labelledby="second-finance">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnalljobs") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-10">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusNameH_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                      @click="loadDataQ02OwnerApi">
                      <i class="material-icons material-symbols-outlined">search</i>
                      {{ $t("buttons.sear") }}
                    </material-button>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.jobId") }}</th>
                          <th>{{ $t("content.dant") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnanalysvalnew") }}</th>
                          <th>{{ $t("content.amnanalysvalreal") }}</th>
                          <th>{{ $t("content.statuspay") }}</th>
                          <th>{{ $t("content.status") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>

                      <tbody v-for="(i, pay2) in listQ02O" :key="pay2" :data-group-index="pay2">
                        <tr class="group-id-ticket" :class="i.status == 'Finish' ? '' : 'bg-working'">
                          <td class="text-sm font-weight-normal">
                            <div class="px-2">{{ pay2 + 1 }}</div>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="9">
                            <div class="group-tickect" style="padding: 0.5rem !important">
                              <h6 class="mb-0">
                                <b>{{ t("content.amngroupticket") }} #{{ pay2 + 1 }} ({{ $t("content.jobId") }}:
                                  {{ i.jobId }}) -
                                  {{ i.customerName }} ({{ $t("content.amntotalval") }} : {{
                                    formatNumberString(i.roundTotalPay)
                                  }}
                                  {{ $t("content.curency") }})
                                </b>
                              </h6>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="white" class="float-lg-start float-sm-end mb-0"
                              @click="toggleRowQ02O(pay2)">
                              <i v-if="i.tg" class="material-icons material-symbols-outlined">expand_less</i>
                              <i v-else class="material-icons material-symbols-outlined">expand_more</i>
                            </material-button>
                          </td>
                        </tr>
                        <template v-if="i.tg">
                          <tr v-for="(j, set2) in i.items" :key="set2">
                            <template v-if="j.ticket !== null">
                              <td class="text-sm font-weight-normal">{{ set2 + 1 }}</td>
                              <td class="text-sm font-weight-normal">{{ j.jobId }}</td>
                              <td class="text-sm font-weight-normal">{{ i.start }}
                              </td>
                              <td class="text-sm font-weight-normal">
                                {{ j.tkRefNumber }}
                              </td>
                              <td class="text-sm font-weight-normal">
                                {{ checkTransaction(j.type) }}
                              </td>
                              <td class="text-sm font-weight-normal" :class="j.totalReceive > 0
                                ? `text-success`
                                : j.totalPay > 0 ? `text-danger` : ''
                                ">
                                <span v-if="j.migration">
                                  <span
                                    v-if="j.migrationType === 'PAWN' || j.migrationType === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                                <span v-else>
                                  <span v-if="j.type === 'PAWN' || j.type === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal" :class="j.totalReceive > 0
                                ? `text-success`
                                : j.totalPay > 0 ? `text-danger` : ''
                                ">
                                <span v-if="j.migration">
                                  <span
                                    v-if="j.migrationType === 'PAWN' || j.migrationType === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                                <span v-else>
                                  <span v-if="j.type === 'PAWN' || j.type === 'INC_PRINCIPLE' && j.totalPay > 0">
                                    {{ formatNumberString(j.totalPay) }}
                                  </span>
                                  <span v-else>
                                    {{ formatNumberString(j.totalReceive) }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <span v-if="j.status !== 'TERMINATE'">
                                  <span v-if="j.waitForCondition"> {{ $t("content.amnstateacting") }}</span>
                                  <span v-if="!j.waitForCondition"> {{ $t("content.amnstateending") }}</span>
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td class=" text-sm font-weight-normal text-center">
                                {{ checkProcess(j.status) }}
                              </td>
                              <td class="text-sm font-weight-normal">{{ i.latestUserName }}</td>
                              <td v-if="set2 == 0" class="text-sm font-weight-normal" :rowspan="i.items.length">
                                <material-button color="info" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('buttons.edit')"
                                  @click="openTab(i)">
                                  <i class="material-icons material-symbols-outlined">edit</i>
                                </material-button>
                                <material-button color="warning" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.amnunlock')"
                                  @click="onUnLockDialog(i)">
                                  <i class="material-icons material-symbols-outlined">lock_open</i>
                                </material-button>
                              </td>
                            </template>

                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="thirdFinance" class="tab-pane fade" role="tabpanel" aria-labelledby="third-finance">

            <div class="card mt-4">
              <div class="card-header">
                <h5>{{ $t("content.amnfinanceinfo") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush table-hover">
                      <thead class="thead-light">
                        <tr>
                          <th>{{ $t("content.cname") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.amnbiz") }}</th>
                          <th>{{ $t("content.amnfinanceoutput") }}</th>
                          <th>{{ $t("content.amnfinanceinput") }}</th>
                          <th>{{ $t("content.status") }}</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(row, index) in listpaymentD.items" :key="index"
                          :class="row.status != 'TERMINATE' ? '' : 'bg-working'">
                          <td class="text-sm font-weight-normal">{{ listpaymentD.customerName }}</td>
                          <td class="text-sm font-weight-normal">{{ row.tkRefNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ checkTransaction(row.type) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.totalPay !== 0" style="color:red;">
                              {{ formatNumberString(row.totalPay) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.totalReceive !== 0" style="color:green;">
                              {{ formatNumberString(row.totalReceive) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal"> {{ checkProcess(row.status) }}</td>
                        </tr>
                      </tbody>
                      <tfoot class="table-light">
                        <tr>
                          <td class="text-sm font-weight-bold" colspan="3">
                            {{ $t("content.amntotaltransact") }}
                          </td>
                          <td class="text-sm font-weight-bold">{{ formatNumberString(listpaymentD.roundTotalPay) }}</td>
                          <td class="text-sm font-weight-bold">{{ formatNumberString(listpaymentD.roundTotalReceive) }}
                          </td>
                          <td class="text-sm font-weight-bold">
                            {{ $t("content.amnpay") }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <hr />

                <div class="row mt-2">
                  <div class="col-12">
                    <h5 :class="datas.showPaid ? `text-success` : ``">
                      {{
                        datas.showPaid
                          ? $t("content.amnpaycompleted")
                          : $t("content.amnfinanceprocess")
                      }}
                      <span class="text-success" :class="datas.showPaid ? `text-deprecated` : ``">({{
                        $t("content.amnpay") }} {{ formatNumberString(listpaymentD.roundTotalPay) }})</span>
                    </h5>
                  </div>
                </div>



                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th style="min-width: 200px;">{{ $t("content.amntypetrans") }}</th>
                          <th>{{ $t("content.amnbankaccno2") }}</th>
                          <th>{{ $t("content.amnhowtomoney") }}</th>
                          <th>{{ $t("content.amninputmoneytypenew") }}</th>
                          <th>{{ $t("content.amninputmoneyreceive") }}</th>
                          <th style="min-width: 150px;">{{ $t("content.amnsourcemoney") }}</th>
                          <th style="min-width: 150px;">{{ $t("content.toaccname") }}</th>
                          <th style="min-width: 200px;">{{ $t("content.amneffectdate") }}</th>
                        </tr>
                      </thead>
                      <tbody>



                        <tr v-for="(row, index) in paymentDetail" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                          <td class="text-sm font-weight-normal">
                            {{ checkPayType(row.type) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.type === 'BANK_TRANSFER'">
                              {{ filterCusBankPay(row.cusBankUid) }}
                            </span>
                            <span v-if="row.type === 'PROMPT_PAY_MOBILE'">
                              {{ createMaskPhone(row.cusPhoneNumber) }} {{ filterCusPromtMobile(row.cusPhoneNumber) }}
                            </span>
                            <span v-if="row.type === 'PROMPT_PAY_TAX_ID'">
                              {{ createMaskID(row.cusIdNumber) }} {{ filterCusPromtTax(row.cusIdNumber) }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.chequeNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.pay !== 0" style="color:red;">
                              {{ formatNumberString(row.pay) }}
                            </span>
                            <span v-else></span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.receive !== 0" style="color:green;">
                              {{ formatNumberString(row.receive) }}
                            </span>
                            <span v-else></span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ checkMoneySource(row.sourcemoney) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.toAccNameTh !== '' && row.toAccNameTh !== null">
                              {{ row.toAccNameTh }} ({{ row.toAccNameEn }})</span>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <!-- row.type === 'CHEQUE' && -->
                            <span v-if="row.effectiveDate !== null"> {{
                              format_effdate(row.effectiveDate) }}
                            </span>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>

                </div>

                <hr />


                <div class="row">
                  <div class="col-lg-12">
                    <label class="form-label ms-0">{{
                      $t("content.amnfinancenote")
                    }}</label>
                    <material-input v-model="detailRemark" id="detailPay" disabled />
                  </div>
                </div>



                <div class="row mt-2">
                  <div class="col-6 d-flex justify-content-between align-items-center">
                    <material-button color="default" class="float-lg-start float-sm-end mb-3" @click="backToSecondTab">
                      <i class="material-icons material-symbols-outlined">close</i>
                      {{ cancel }}
                    </material-button>

                  </div>

                  <div class="col-6 d-flex justify-content-end align-items-center">

                    <div class="me-3">
                      <material-button color="danger" class="float-lg-start float-sm-end mb-3"
                        :disabled="workItemUid === ''" @click="onReturnDialog">
                        <i class="material-icons material-symbols-outlined">reply</i>
                        {{ reject }}
                      </material-button>
                    </div>

                    <div class="me-3">
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="workItemUid === ''" @click="onCompleteDialog">
                        {{ approve }}
                        <i class="material-icons material-symbols-outlined">forward</i>
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docno") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td></td>
                          <td>
                            <material-input v-model="nameThC" type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input v-model="nameEnC" type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input v-model="docNumberC" type="text" :label="t('content.docno')" />
                          </td>
                          <td>
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              :disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
                              @click="loadCustomerAfListApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                          <td>{{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}</td>
                          <td>{{ row.nameTh }}</td>
                          <td>{{ row.nameEn }}</td>
                          <td colspan="2">{{ row.docNumber }}</td>
                        </tr>
                        <tr v-if="customerlist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                          :items-per-page="perPageC" @click="onClickHandlerC" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- OTP Dialog -->
        <div id="otpModal" class="modal fade" tabindex="-1" aria-labelledby="otpModalLabel" aria-hidden="true">
          <div class="modal-dialog" style="max-width: 500px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-center">{{ $t("content.amnotpcode") }}</h4>
              </div>

              <div class="modal-body">
                <section class="panel">
                  <otp :digit-count="6" @update:otp="otpValue = $event" />
                  <p class="text-center mt-5">
                    {{ $t(`content.amnotptime`) }} : 5 {{ $t(`content.amnmin`) }}
                  </p>
                </section>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                  {{ confirm }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */

import { ref, reactive, inject, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
// import MaterialRadio from "@/components/MaterialRadio.vue"

import otp from "@/examples/OTP/otp.vue";
import QrCode from "@/assets/img/pawn/qr_sample.png";

import { useWorkItemStore } from '@/stores/workItem'
import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useBankStore } from '@/stores/bank'
import { useCashAccountStore } from '@/stores/cashaccount'
import { useShopBankStore } from '@/stores/shopbank'
import { useCustomerStore } from '@/stores/customer'
import { useCusBankStore } from '@/stores/cusbank'
import { useCusAffiliateStore } from '@/stores/cusaffiliate'
import { useDocTypeStore } from '@/stores/doctype'

import moment from 'moment'
import * as st from "@/config/setting.js";

//   import QrCode from "@/assets/img/pawn/qr_sample.png";

const { t } = useI18n();
const swal = inject("$swal");

const otpValue = ref("");

const datas = reactive({
  showQR: false,
  searchBox1: "",
  paid: false,
  showPaid: false,
});

///// computed
// const checked = computed(() => t("content.amnrecheckdone"));
// const save = computed(() => t("buttons.save"))
const approve = computed(() => t("buttons.apprv"));
const reject = computed(() => t("buttons.rejec"));
const cancel = computed(() => t("buttons.canc"));
const edit = computed(() => t("buttons.edit"));
const confirm = computed(() => t("buttons.conf"));


const listQ02 = ref([]);
const currentPage_Q02 = ref(1);
const totalRows_Q02 = ref(1);
const perPage_Q02 = ref(10);
const totalRows_s_Q02 = ref();

const listQ02O = ref([]);
const currentPage_Q02O = ref(1);
const totalRows_Q02O = ref(1);
const perPage_Q02O = ref(10);
const totalRows_s_Q02O = ref();



const toggleRowQ02 = (index) => {
  // console.log('index :' + index + " : " + listQ02[index]);
  if (!listQ02.value[index].tg) {
    listQ02.value[index].tg = true;
  } else {
    listQ02.value[index].tg = false;
  }
};

const toggleRowQ02O = (index) => {
  // console.log('index :' + index + " : " + listQ02[index]);
  if (!listQ02O.value[index].tg) {
    listQ02O.value[index].tg = true;
  } else {
    listQ02O.value[index].tg = false;
  }
};


const storew = useWorkItemStore()
const storep = usePaymentGroupStore()
const storeb = useBankStore()
const storec = useCashAccountStore()
const storesb = useShopBankStore()
const storecus = useCustomerStore()
const storecb = useCusBankStore()
const storecusa = useCusAffiliateStore()
const storedt = useDocTypeStore()


const paymentE = ref({});
const listpaymentE = ref([]);
const listpaymentD = ref([]);
const paymentDetail = ref([]);

const listbank = ref([]);
const listcashAcc = ref([]);
const listbankpawn = ref([]);

const shop_detail = ref(null)

const version_edit = ref('');
const workItemUid = ref('');
const detailRemark = ref('');

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')
const cusUid_search = ref('');
const cusName_search = ref('');
const cusUidH_search = ref('');
const cusNameH_search = ref('');
const activeTab = ref('tab1');

const customerlist = ref([])

const listcusbankAll = ref([]);

const listcusmobileAll = ref([]);
const customerAflist = ref(null)
const doctypeAccList = ref([])
const listcusbankAf = ref([]);
const customerTaxlist = ref([])

const cus_detail = ref({
  nameTh: '',
  nameEn: '',
  birthOrRegDate: new Date(),
  address: {
    address1: "",
    subDistrict: "",
    district: "",
    province: "",
    postcode: "",
    country: ""
  },
  issuedBy: '',
  issuedDate: new Date(),
  expiredDate: new Date(),
  version: '',
  age: 0,
  docTypeUid: '0',
  docNumber: '',
  cusShop: {
    cusTypeUid: "0",
    creditAmount: 0,
    email: "",
    phoneNo1: "",
    phoneNo2: "",
    phoneNo3: "",
  }
})


const onClickHandlerC = (page) => {
  updatePaginationC()
};

const loadDataQ02Api = async (val) => {

  let data = "";
  data = {
    index: '0',
    size: '100',
    cusUid: cusUid_search.value,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storew.listPaymentQ03Fetch(payload);
  // const response = await storep.paymentGrouplistFetch(payload);
  // console.log("loadDataApi Q02 : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {

        let listd = response.data.data.list;


        listd.map(function (x) {
          x.tg = false;
          x.chk = false;
          x.start = null
          x.status = null
          x.status_s = null



          return x
        });

        for (let i = 0; i < listd.length; i++) {
          listd[i].start = format_date(new Date(listd[i].startTime))
          let exist = listd[i].items.filter((item) => {
            return item.waitForCondition === true && item.status !== 'TERMINATE';
          })
          // console.log("exist " + [i] + " : " + JSON.stringify(exist));
          if (exist.length > 0) {
            listd[i].status = 'Working'
            listd[i].status_s = 'กำลังดำเนินการ'
          } else {
            listd[i].status = 'Finish'
            listd[i].status_s = 'พร้อมชำระ'
            listd[i].tg = true
            // console.log("chk " + [i] + " : " + listd[i].chk);
          }
          // console.log(" customerInfo " + [i] + " : " + JSON.stringify(listd[i].workCases[0].customerInfo.name));
          // console.log(" workItems " + [i] + " : " + JSON.stringify(listd[i].workCases[0].workItems[0].startTime));
          // console.log(" workItems xx " + listd[i].workCases[0].workItems[0].waitForCondition);

        }

        listQ02.value = listd;
        // console.log("listd Q02 : " + JSON.stringify(listd));
        totalRows_Q02.value = response.data.data.ext.total
        totalRows_s_Q02.value = formatNumberString(totalRows_Q02.value)
      }
    }
  }
};

loadDataQ02Api()



const loadDataQ02OwnerApi = async (val) => {

  let data = "";
  data = {
    index: '0',
    size: '100',
    cusUid: cusUidH_search.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storew.listPaymentQ03OwnerFetch(payload);
  // console.log("loadDataApi Q02O : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;


        listd.map(function (x) {
          x.tg = false;
          x.chk = false;
          x.start = null
          x.status = null
          x.status_s = null




          return x
        });

        for (let i = 0; i < listd.length; i++) {
          listd[i].start = format_date(new Date(listd[i].startTime))
          let exist = listd[i].items.filter((item) => {
            return item.waitForCondition === true && item.status !== 'TERMINATE';
          })
          // console.log("exist " + [i] + " : " + JSON.stringify(exist));
          if (exist.length > 0) {
            listd[i].status = 'Working'
            listd[i].status_s = 'กำลังดำเนินการ'
          } else {
            listd[i].status = 'Finish'
            listd[i].status_s = 'พร้อมชำระ'
            listd[i].tg = true
            // console.log("chk " + [i] + " : " + listd[i].chk);
          }

          // console.log(" customerInfo " + [i] + " : " + JSON.stringify(listd[i].workCases[0].customerInfo.name));
          // console.log(" workItems " + [i] + " : " + JSON.stringify(listd[i].workCases[0].workItems[0].startTime));
          // console.log(" workItems xx " + listd[i].workCases[0].workItems[0].waitForCondition);

        }

        listQ02O.value = listd;
        // console.log("listd Q02 : " + JSON.stringify(listd));
        totalRows_Q02O.value = response.data.data.ext.total
        totalRows_s_Q02O.value = formatNumberString(totalRows_Q02.value)
      }
    }
  }
};

const loadBankDataApi = async (val) => {
  listbank.value = [];
  let data = "";
  data = {
    index: "0",
    size: "100",
    nameTh: "",
    nameEn: "",
  };
  const payload = data;
  const response = await storeb.bankListFetch(payload);
  // console.log("loadBankDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listbank.value = response.data.data.list;
      }
    }
  }
}

// loadBankDataApi()


const loadCashAccDataApi = async (val) => {
  listcashAcc.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.cashaccountListFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcashAcc.value = response.data.data.list;
      }
    }
  }
}

const loadBankPawnApi = async (val) => {
  listbankpawn.value = [];
  let data = "";
  data = {
    index: 0,
    size: 100,
    bankUid: '',
    shortName: '',
    acctNumber: '',
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesb.shopbankListFetch(payload);
  // console.log("loadBankPawnApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listbankpawn.value = response.data.data.list;
      }
    }
  }
}

const cashAccShopApi = async (val) => {
  shop_detail.value = null
  const response = await storec.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("cashAccShopApi : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        loadCashAccDataApi()
        loadBankPawnApi()

      }
    }
  }
}

// cashAccShopApi()


const loadDocTypeAccListApi = async (val) => {
  // dev : 601433176200681039 , uat : 635673099141979585 , prod : 635691584740292063
  doctypeAccList.value = []
  let data = '';
  data = {
    index: 0,
    size: 100,
    name: "",
    docGroupCode: '001'
  };

  const payload = data;
  const response = await storedt.docTypeListFetch(payload);
  // console.log("loadDocTypeListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          doctypeAccList.value = response.data.data.list;
          // console.log('list : '+list.value.length)
        }
      }
    }
  }
};


function createMaskPhone(string) {
  var x = string.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  string = '(' + x[1] + ') ' + x[2] + '-' + x[3]
  return string;
}

function createMaskID(string) {
  var x = string.replace(/\D/g, '').match(/(\d{0,1})(\d{0,4})(\d{0,5})(\d{0,2})(\d{0,1})/);
  string = x[1] + ' ' + x[2] + ' ' + x[3] + ' ' + x[4] + ' ' + x[5]
  return string;
}

function format_effdate(value) {
  if (value) {
    // return moment.utc(String(value)).local().format('DD/MM/YYYY HH:mm')
    return moment(String(value)).format('DD/MM/YYYY')
  }
}



function checkProcess(str) {
  let side = ''
  if (str === 'FINISH') {
    side = 'เสร็จสิ้น'
  } else if (str === 'ACTIVE') {
    side = 'กำลังดำเนินการ'
  } else if (str === 'FREEZE') {
    side = 'อายัด'
  } else if (str === 'TERMINATE') {
    side = 'ยกเลิก'
  }
  return side
}


async function onClickTab(val) {
  if (val === '1') {
    activeTab.value = 'tab1';
    loadDataQ02Api()
    await setfirstTab()
  } else if (val === '2') {
    activeTab.value = 'tab2';
    loadDataQ02OwnerApi()
    await setsecondTab()
  } else if (val === '3') {
    activeTab.value = 'tab3';
    await setthirdTab()
  }
}

function formatNumberString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}


function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
    // return moment.utc(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}


function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}

async function openTab(item) {
  version_edit.value = ''
  detailRemark.value = ""
  listcusbankAll.value = []
  listcusmobileAll.value = []
  customerAflist.value = null
  customerTaxlist.value = []
  if (listbank.value.length === 0) {
    loadBankDataApi()
  }

  if (shop_detail.value === null) {
    cashAccShopApi()
  }

  if (doctypeAccList.value.length > 0) {
    loadDocTypeAccListApi()
  }
  await detailPayment(item)
  // console.log("openTab 1 : " + JSON.stringify(item));
  // console.log("workCases : " + JSON.stringify(paymentE.value.workCases));
  // console.log("payments : " + JSON.stringify(paymentE.value.payments));
  // console.log("openTab 2 : " + JSON.stringify(paymentE.value.workCases) + " L : " + paymentE.value.workCases.length);
  paymentDetail.value = paymentE.value.payments
  listpaymentE.value = paymentE.value.workCases
  listpaymentD.value = item
  await loadCusBankListAll()
  await getCustomerInfo(listpaymentD.value.customerUid)
  if (cus_detail.value !== null) {
    if (cus_detail.value.cusShop.phoneNo1 !== '' && cus_detail.value.cusShop.phoneNo1 !== null) {
      let obj = {
        mobile: cus_detail.value.cusShop.phoneNo1
      }
      listcusmobileAll.value.push(obj)
    }
    if (cus_detail.value.cusShop.phoneNo2 !== '' && cus_detail.value.cusShop.phoneNo2 !== null) {

      let obj = {
        mobile: cus_detail.value.cusShop.phoneNo2
      }
      listcusmobileAll.value.push(obj)

    }
    if (cus_detail.value.cusShop.phoneNo3 !== '' && cus_detail.value.cusShop.phoneNo3 !== null) {

      let obj = {
        mobile: cus_detail.value.cusShop.phoneNo3
      }
      listcusmobileAll.value.push(obj)
    }

    if (cus_detail.value.docTypeUid !== null && cus_detail.value.docTypeUid !== '0') {
      let dn = doctypeAccList.value.filter((c) => c.uid === cus_detail.value.docTypeUid)
      if (dn.length > 0) {
        if (dn[0].name === 'บัตรประชาชน') {
          let obj = {
            taxId: cus_detail.value.docNumber
          }
          customerTaxlist.value.push(obj)
        }
      }
    }
  }
  if (listpaymentE.value.length > 0) {
    let relate = '0'
    for (let i = 0; i < listpaymentE.value.length; i++) {
      if (listpaymentE.value[i].ticket !== null && listpaymentE.value[i].transactionType === 'PAWN') {
        // console.log('relatedCusUid  ' + i + ' : ' + JSON.stringify(listpaymentE.value[i].ticket.relatedCusUid));
        relate = listpaymentE.value[i].ticket.relatedCusUid
        break
      }
    }

    if (relate !== '0') {
      await loadCusAffiliateListApi(relate)
      if (customerAflist.value.length > 0) {
        await findCusAffiliate()
      }
    }

  }
  version_edit.value = item.version
  workItemUid.value = item.workItemUid
  detailRemark.value = paymentE.value.remark
  // console.log("paymentDetail : " + JSON.stringify(paymentDetail.value));
  if (paymentDetail.value.length > 0) {
    paymentDetail.value.map(function (x) {
      x.sourcemoney = null
      return x
    });

    for (let i = 0; i < paymentDetail.value.length; i++) {
      let sourcm = '0'
      if (paymentDetail.value[i].cashAcctUid !== '0') {
        sourcm = paymentDetail.value[i].cashAcctUid
      }
      if (paymentDetail.value[i].shopBankUid !== '0') {
        sourcm = paymentDetail.value[i].shopBankUid
      }
      paymentDetail.value[i].sourcemoney = sourcm
    }
  }


  await setthirdTab()

}

async function findCusAffiliate() {
  customerAflist.value.map(function (x) {
    x.listbankcus = [];
    x.listpromttax = [];
    x.listpromtmobile = [];
    return x
  });

  for (let i = 0; i < customerAflist.value.length; i++) {
    cus_detail.value = null
    listcusbankAf.value = []
    await getCustomerInfo(customerAflist.value[i].affiliateCusUid)
    await loadBankAf(customerAflist.value[i].affiliateCusUid)

    if (listcusbankAf.value.length > 0) {
      customerAflist.value[i].listbankcus = listcusbankAf.value
    }
    if (cus_detail.value !== null) {

      if (cus_detail.value.cusShop.phoneNo1 !== '' && cus_detail.value.cusShop.phoneNo1 !== null) {

        let obj = {
          mobile: cus_detail.value.cusShop.phoneNo1
        }

        customerAflist.value[i].listpromtmobile.push(obj)
      }

      if (cus_detail.value.cusShop.phoneNo2 !== '' && cus_detail.value.cusShop.phoneNo2 !== null) {

        let obj = {
          mobile: cus_detail.value.cusShop.phoneNo2
        }

        customerAflist.value[i].listpromtmobile.push(obj)

      }

      if (cus_detail.value.cusShop.phoneNo3 !== '' && cus_detail.value.cusShop.phoneNo3 !== null) {

        let obj = {
          mobile: cus_detail.value.cusShop.phoneNo3
        }

        customerAflist.value[i].listpromtmobile.push(obj)

      }

      if (cus_detail.value.docTypeUid !== null && cus_detail.value.docTypeUid !== '0') {

        let dn = doctypeAccList.value.filter((c) => c.uid === cus_detail.value.docTypeUid)
        if (dn.length > 0) {
          if (dn[0].name === 'บัตรประชาชน') {
            let obj = {
              taxId: cus_detail.value.docNumber
            }
            customerAflist.value[i].listpromttax.push(obj)
          }
        }
      }

    }
  }

  // console.log("customerAflist : " + JSON.stringify(customerAflist.value));

}

async function getCustomerInfo(uid) {

  let data = "";
  data = {
    uid: uid,
  };

  const payload = data;

  const response = await storecus.customerInfoFetch(payload);
  // console.log("getCustomerInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        cus_detail.value = response.data.data
      }
    }
  }
}

async function loadCusAffiliateListApi(cusuid) {
  customerAflist.value = []
  let data = "";
  let list = []
  data = {
    index: 0,
    size: 100,
    cusUid: listpaymentD.value.customerUid,
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecusa.cusAffiliateListFetch(payload);
  // console.log("loadCusAffiliateListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        customerAflist.value = list.filter((c) => c.affiliateCusUid === cusuid);
      }
    }
  }
};

async function loadBankAf(id) {
  let data = "";
  data = {
    index: 0,
    size: 100,
    cusUid: id,
  };

  const payload = data;
  const response = await storecb.cusbankListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusbankAf.value = response.data.data.list;
      }
    }
  }
}


async function setfirstTab() {

  if (document.getElementById("second-finance")) {
    document.getElementById("second-finance").classList.remove('show', 'active')
    // console.log("found 2 !! ");
  }

  if (document.getElementById("secondFinance")) {
    document.getElementById("secondFinance").classList.remove('show', 'active')
    // console.log("found 2x !! ");
  }

  if (document.getElementById("third-finance")) {
    document.getElementById("third-finance").classList.remove('show', 'active')
    // console.log("found 3 !! ");
  }

  if (document.getElementById("thirdFinance")) {
    document.getElementById("thirdFinance").classList.remove('show', 'active')
    // console.log("found 3x !! ");
  }

  if (document.getElementById("first-finance")) {
    document.getElementById("first-finance").classList.add('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstFinance")) {
    document.getElementById("firstFinance").classList.add('show', 'active')
    // console.log("found 1x !! ");
  }

}


async function setsecondTab() {

  if (document.getElementById("first-finance")) {
    document.getElementById("first-finance").classList.remove('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstFinance")) {
    document.getElementById("firstFinance").classList.remove('show', 'active')
    // console.log("found 1x !! ");
  }

  if (document.getElementById("third-finance")) {
    document.getElementById("third-finance").classList.remove('show', 'active')
    // console.log("found 3 !! ");
  }

  if (document.getElementById("thirdFinance")) {
    document.getElementById("thirdFinance").classList.remove('show', 'active')
    // console.log("found 3x !! ");
  }

  if (document.getElementById("second-finance")) {
    document.getElementById("second-finance").classList.add('show', 'active')
    // console.log("found 2 !! ");
  }

  if (document.getElementById("secondFinance")) {
    document.getElementById("secondFinance").classList.add('show', 'active')
    // console.log("found 2x !! ");
  }
}


async function setthirdTab() {

  if (document.getElementById("first-finance")) {
    document.getElementById("first-finance").classList.remove('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstFinance")) {
    document.getElementById("firstFinance").classList.remove('show', 'active')
    // console.log("found 1x !! ");
  }

  if (document.getElementById("second-finance")) {
    document.getElementById("second-finance").classList.remove('show', 'active')
    // console.log("found 2 !! ");
  }

  if (document.getElementById("secondFinance")) {
    document.getElementById("secondFinance").classList.remove('show', 'active')
    // console.log("found 2x !! ");
  }


  if (document.getElementById("third-finance")) {
    document.getElementById("third-finance").classList.add('show', 'active')
    // console.log("found 3 !! ");
  }

  if (document.getElementById("thirdFinance")) {
    document.getElementById("thirdFinance").classList.add('show', 'active')
    // console.log("found 3x !! ");
  }

}

async function selectCusAf(item) {
  if (activeTab.value === 'tab1') {
    cusUid_search.value = item.uid
    cusName_search.value = item.nameTh
  } else if (activeTab.value === 'tab2') {
    cusUidH_search.value = item.uid
    cusNameH_search.value = item.nameTh
  }
  document.getElementById("closeModal").click();
}

async function openDialogCus() {
  if (activeTab.value === 'tab1') {
    cusUid_search.value = ''
    cusName_search.value = ''
  } else if (activeTab.value === 'tab2') {
    cusUidH_search.value = ''
    cusNameH_search.value = ''
  }
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  customerlist.value = []
  document.getElementById("searchNameCus").click();
}




async function loadCustomerAfListApi() {
  currentPageC.value = 1
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerlist.value = listd;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}

async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}

async function onUnLockDialog(item) {
  swal({
    title: "ปลดล็อกรายการ",
    text: "คุณต้องการปลดล็อกรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onUnLock(item)
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}


function filterCash(str) {
  return listcashAcc.value.filter((c) => c.uid === str);
}

function filterBank(str) {
  return listbankpawn.value.filter((c) => c.uid === str);
}

function filterCusBank(str) {
  return listbank.value.filter((c) => c.uid === str);
}

function filterCusBankPay(str) {
  let bd = ''
  let lp = []
  lp = listcusbankAll.value.filter((c) => c.uid === str);
  if (lp.length > 0) {
    bd = filterCusBank(lp[0].bankUid)[0].nameTh + ' ' + lp[0].accNumber + ' (' + lp[0].accName + ')'
  } else {
    if (customerAflist.value !== null) {
      if (customerAflist.value.length > 0) {
        for (let i = 0; i < customerAflist.value.length; i++) {
          if (customerAflist.value[i].listbankcus !== undefined) {
            lp = customerAflist.value[i].listbankcus.filter((c) => c.uid === str);
            if (lp.length > 0) {
              // console.log('customerAflist xxx : ' + JSON.stringify(lp));
              bd = filterCusBank(lp[0].bankUid)[0].nameTh + ' ' + lp[0].accNumber + ' (' + lp[0].accName + ')'
              break
            }
          }
        }
      }
    }


  }
  return bd
}


function filterCusPromtMobile(str) {
  let bd = ''
  let lp = []
  lp = listcusmobileAll.value.filter((c) => c.mobile === str);
  if (lp.length > 0) {
    bd = ' (' + listpaymentD.value.customerName + ')'
  } else {
    if (customerAflist.value !== null) {
      if (customerAflist.value.length > 0) {
        for (let i = 0; i < customerAflist.value.length; i++) {
          if (customerAflist.value[i].listpromtmobile !== undefined) {
            lp = customerAflist.value[i].listpromtmobile.filter((c) => c.mobile === str);
            if (lp.length > 0) {
              // console.log('customerAflist xxx : ' + JSON.stringify(lp));
              bd = ' (' + customerAflist.value[i].nameTh + ')'
              break
            }
          }
        }
      }
    }
  }
  return bd
}

function filterCusPromtTax(str) {
  let bd = ''
  let lp = []
  lp = customerTaxlist.value.filter((c) => c.taxId === str);
  if (lp.length > 0) {
    bd = ' (' + listpaymentD.value.customerName + ')'
  } else {
    if (customerAflist.value !== null) {
      if (customerAflist.value.length > 0) {
        for (let i = 0; i < customerAflist.value.length; i++) {
          if (customerAflist.value[i].listpromttax !== undefined) {
            lp = customerAflist.value[i].listpromttax.filter((c) => c.taxId === str);
            if (lp.length > 0) {
              // console.log('customerAflist xxx : ' + JSON.stringify(lp));
              bd = ' (' + customerAflist.value[i].nameTh + ')'
              break
            }
          }
        }
      }
    }
  }
  // console.log('bd xxx : ' + bd);
  return bd
}


async function loadCusBankListAll() {

  let data = "";
  data = {
    index: 0,
    size: 100,
    cusUid: listpaymentD.value.customerUid,
  };
  // console.log("cus uid bk : " + cus_detail.value.uid);
  const payload = data;
  // console.log("loadCusBankListAll : " + JSON.stringify(payload));
  const response = await storecb.cusbankListFetch(payload);
  // console.log("loadCusBankListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusbankAll.value = response.data.data.list;
        // console.log("loadCusBankListApi : " + JSON.stringify(listcusbankAll.value));
      }
    }
  }
}

function checkPayType(str) {
  let side = ''
  if (str === 'CASH') {
    side = t("content.amncash")
  } else if (str === 'BANK_TRANSFER') {
    side = t("content.amntransfer")
  } else if (str === 'CREDIT_CARD') {
    side = t("content.amncreditcard")
  } else if (str === 'CHEQUE') {
    side = t("content.amncheque")
  } else if (str === 'PROMPT_PAY_MOBILE') {
    side = t("content.amntransfer_promt_mobile")
  } else if (str === 'PROMPT_PAY_TAX_ID') {
    side = t("content.amntransfer_promt_taxid")
  }
  return side
}


function checkMoneySource(str) {
  let side = ''
  if (str !== '0') {
    let ld = filterBank(str)
    if (ld.length > 0) {
      side = ld[0].shortName
    } else {
      ld = filterCash(str)
      if (ld.length > 0) {
        side = ld[0].name
      }
    }
  }
  return side
}


async function completeStep(item) {
  let data = "";
  data = {
    uid: item.uid,
    action: item.action,
    version: item.version,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);
  const response = await storew.completeStepFetch(payload);
  // console.log('completeStep : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        backToSecondTab()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function chkSelect() {
  let process = false
  let lockL = []
  for (let i = 0; i < listQ02.value.length; i++) {
    if (listQ02.value[i].chk) {
      process = true
      // console.log('chkSelect u : ' + JSON.stringify(listQ02.value[i].workItemUid) + ' v : ' + JSON.stringify(listQ02.value[i].version));
      lockL.push({ uid: listQ02.value[i].workItemUid, version: listQ02.value[i].version })

    }
  }

  if (!process) {
    swal("กรุณาเลือกรายการ", "", "error");
  } else {
    // console.log('lockL : ' + JSON.stringify(lockL));
    await lock(lockL)
  }

}

async function onUnLock(item) {
  // console.log('onUnLock : ' + JSON.stringify(item.workItemUid) + " version : " + JSON.stringify(item.version));
  let lockL = []
  lockL.push({ uid: item.workItemUid, version: item.version })
  await unlock(lockL)

}



async function lock(item) {
  let data = "";
  data = {
    lock: item,
  };
  const payload = data;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storew.lockFetch(payload);
  // console.log('response lock : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataQ02Api()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function unlock(item) {
  // console.log('item : ' + JSON.stringify(item.uid));
  let data = "";
  data = {
    lock: item,
  };
  const payload = data;
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storew.unlockFetch(payload);
  // console.log('response : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        if (workItemUid.value !== '' && workItemUid.value === item[0].uid) {
          backToSecondTab()
        } else {
          loadDataQ02OwnerApi()
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function detailPayment(item) {
  paymentE.value = null
  let data = "";
  data = {
    uid: item.paymentGroupUid,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);
  const response = await storep.paymentGroupinfoFetch(payload);
  // console.log('detailPayment : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value = response.data.data
        // loadDataApi('update');
      }
    }
  }
}


async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      "</section>" +
      // '</div>' +
      // '<div class="preview-detail">' +
      // '<h6 class="mb">Notice:</h6>' +
      // '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      "</div>",
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

async function onReturnDialog() {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการตีกลับรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      onReturn()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}


async function onCompleteDialog() {
  swal({
    title: "ตรวจสอบรายการ",
    text: "คุณต้องการอนุมัติรายการนี้",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      onComplete()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error")
    }
  });
}


async function onCancelPayment() {
  swal({
    title: "ยกเลิกรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.clos"),
  }).then((result) => {
    if (result.isConfirmed) {
      backToSecondTab()
    }
  });
}


async function onComplete() {
  // console.log('onComplete : ' + workItemUid.value + " version : " + version_edit.value + " pay : " + JSON.stringify(paymentDetail.value));

  let obj = {
    uid: workItemUid.value,
    action: 'complete',
    version: version_edit.value
  }
  await completeStep(obj)
}

async function onReturn() {
  // console.log('onReturn : ' + workItemUid.value + " version : " + version_edit.value);
  let obj = {
    uid: workItemUid.value,
    action: 'return',
    version: version_edit.value,
    process: 'return'
  }
  await completeStep(obj)
}


async function backToSecondTab() {
  listpaymentE.value = []
  listpaymentD.value = {}
  version_edit.value = ''
  workItemUid.value = ''
  paymentDetail.value = []
  detailRemark.value = ''
  customerAflist.value = null
  listcusmobileAll.value = []
  customerTaxlist.value = []
  await onClickTab('2')
}

///// methods
// const calPercent = (val, full) => {
//   return (parseInt(val) * 100) / parseInt(full)
// }

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val);
};

onMounted(() => {
  if (document.getElementById("choices-month")) {
    var month = document.getElementById("choices-month");
    setTimeout(function () {
      new Choices(month);
    }, 1);

    // eslint-disable-next-line no-unused-vars
    var d = new Date();
    var monthArray = new Array();
    monthArray[0] = "January";
    monthArray[1] = "February";
    monthArray[2] = "March";
    monthArray[3] = "April";
    monthArray[4] = "May";
    monthArray[5] = "June";
    monthArray[6] = "July";
    monthArray[7] = "August";
    monthArray[8] = "September";
    monthArray[9] = "October";
    monthArray[10] = "November";
    monthArray[11] = "December";
    for (let m = 0; m <= 11; m++) {
      var optn = document.createElement("OPTION");
      optn.text = monthArray[m];
      // server side month start from one\
      optn.value = m + 1;
      // if june selected
      if (m == 1) {
        optn.selected = true;
      }
      month.options.add(optn);
    }
  }

  if (document.getElementById("choices-day")) {
    var day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day);
    }, 1);

    for (let y = 1; y <= 31; y++) {
      var optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }

  if (document.getElementById("choices-year")) {
    var year = document.getElementById("choices-year");
    setTimeout(function () {
      new Choices(year);
    }, 1);

    for (let y = 1900; y <= 2020; y++) {
      var optn2 = document.createElement("OPTION");
      optn2.text = y;
      optn2.value = y;

      if (y == 2020) {
        optn.selected = true;
      }

      year.options.add(optn2);
    }
  }
  if (document.getElementById("first-finance")) {
    document.getElementById("first-finance").classList.add('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstFinance")) {
    document.getElementById("firstFinance").classList.add('show', 'active')
    // console.log("found 1x !! ");
  }
});
</script>

<style lang="scss" scoped></style>
