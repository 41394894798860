<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <ul id="seizeTab" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="tab1-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab"
              aria-controls="tab1" @click="onClickTab('1')">
              {{ $t("content.amnseize") }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="tab2-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab"
              aria-controls="tab2" @click="onClickTab('2')">
              {{ $t("content.historyseize") }}
            </button>
          </li>
        </ul>

        <div id="seizeTabContent" class="tab-content">
          <div id="tab1" class="tab-pane fade" role="tabpanel" aria-labelledby="tab1-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.amnseize") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusName_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.ticketno") }}</label>
                    <material-input type="text" v-model="tk_search" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="tk_search === '' && cusName_search === ''" @click="loadDataApi">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ btnSearch }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelDataBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ cancel }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.lasttrf") }}</th>
                          <th>{{ $t("content.names") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.transtype") }}</th>
                          <th>{{ $t("content.amnweightvalall") }}</th>
                          <th>{{ $t("content.amnqtyunitall") }}</th>
                          <th>{{ $t("content.allprice") }}</th>
                          <th>{{ $t("content.statusticket") }}</th>
                          <th>{{ $t("content.amnlastmaker") }}</th>
                          <th>{{ $t("content.lostcount") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listdata" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPage - 1) * perPage +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">
                            <span v-if="row.represent">
                              {{ row.tkCustomerName }}
                            </span>
                            <span v-else>
                              {{ row.customerName }}
                            </span>
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal"> {{ checkTransaction(row.type) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.weight) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.quantity) }}</td>
                          <td class="text-sm font-weight-normal">{{ formatNumberDecimalString(row.newPawnValue) }}</td>
                          <td class="text-sm font-weight-normal">{{ checkTicketStatus(row.status) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.shopManagerName }}</td>
                          <td class="text-sm font-weight-normal">{{ row.lostCount }}</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)">
                              <i class="material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              :title="t('content.managelost')" @click="openDialogLostAdd(row)"
                              :disabled="row.status !== 'FINISH'">
                              <i class="material-icons material-symbols-outlined">insert_page_break</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lostTicketModal" id="lostmodal">
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              :title="t('content.managefreeze')" @click="openDialogFreezeAdd(row)"
                              :disabled="row.status !== 'FINISH'">
                              <i class="material-icons material-symbols-outlined">block</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#seizeModal" id="freezemodal">
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="t('content.historyview')"
                              :disabled="row.lostCount === 0" @click="viewHistory(row)">
                              <i class="material-icons material-symbols-outlined">navigate_next</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="listdata.length === 0">
                          <td colspan="11" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="14" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listdata.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                          @click="onClickHandler" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="tab2" class="tab-pane fade" role="tabpanel" aria-labelledby="tab2-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t("content.historyseize") }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-12 mb-4">
                    <div class="d-flex align-items-center w-100">
                      <label class="form-label m-0">{{ $t("content.item") }}</label>
                      <material-radio id="seize2" v-model="selectSeize" name="selInt" class="radio-horizon" value="0">
                        {{ $t("content.amnticketlost") }}
                      </material-radio>
                      <material-radio id="seize3" v-model="selectSeize" name="selInt" class="radio-horizon" value="1">
                        {{ $t("content.amnseizeact") }}
                      </material-radio>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.names") }}</label>
                    <div class="input-group">
                      <input v-model="cusNameH_search" type="text" class="form-control" aria-describedby="searchName"
                        readonly />
                      <button class="btn btn-primary" type="button" @click="openDialogCus">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.ticketno") }}</label>
                    <material-input type="text" v-model="tkH_search" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3"
                        :disabled="cusNameH_search === '' && tkH_search === ''" @click="chkSelectHistory">
                        <i class="material-icons material-symbols-outlined">search</i>
                        {{ btnSearch }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelHistoryBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ cancel }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 70px">#</th>
                          <th>{{ $t("content.amndantinfo") }}</th>
                          <th style="min-width: 200px">{{ $t("content.names") }}</th>
                          <th>{{ $t("content.ticketno") }}</th>
                          <th>{{ $t("content.note") }}</th>
                          <th>{{ $t("content.file") }}</th>
                          <th>{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in listhistory" :key="index">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPage - 1) * perPage +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.createDate) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.customerName }}</td>
                          <td class="text-sm font-weight-normal">{{ row.refNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ row.remark }}</td>
                          <td class="text-sm font-weight-normal">
                            <!-- :disabled="row.plDocUid === '0' || selectRdo === '0' && index !== listhistory.length - 1" -->
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              v-if="row.plDocUid !== '0' || selectRdo !== '0' && index === listhistory.length - 1"
                              @click="getFileTicket(row)" :title="t('content.fileview')">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="modal" data-bs-target="#attachmentsModal" v-show="false"
                              id="attachmenDialog">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              :title="t('buttons.seed')" @click="openDetail(row)"
                              v-show="selectRdo === '1' || selectRdo === '0' && index === listhistory.length - 1">
                              <i class=" material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" v-show="false"
                              data-bs-toggle="modal" data-bs-target="#lookupModal" id="detailModal">
                            </material-button>
                            <material-button v-show="selectRdo === '0' && index === listhistory.length - 1"
                              color="warning" variant="outline" class="rounded-circle" @click="openDialogUpdate(row)"
                              :title="t('content.updatelost')">
                              <i class="material-icons material-symbols-outlined">edit_note</i>
                            </material-button>
                            <material-button v-show="selectRdo === '1'" color="warning" variant="outline"
                              class="rounded-circle" @click="openDialogUpdate(row)" :title="t('content.updatefreeze')">
                              <i class="material-icons material-symbols-outlined">edit_note</i>
                            </material-button>
                            <material-button v-show="false" color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="modal" data-bs-target="#updateLostModal" id="updateLost">
                            </material-button>
                            <material-button v-show="false" color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="modal" data-bs-target="#updateFreezeModal" id="updateFreeze">
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              v-show="selectRdo === '0' && index === listhistory.length - 1" @click="lostTicketGen(row)"
                              :title="t('content.printticketlost')">
                              <i class="material-icons material-symbols-outlined">insert_page_break</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              v-show="selectRdo === '1'" @click="openDialogCopy(row)" :title="t('content.copyprint')">
                              <i class="material-icons material-symbols-outlined">print</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle" id="copyModal"
                              data-bs-toggle="modal" data-bs-target="#copyPrintModal" v-show="false">
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              v-show="selectRdo === '1'" @click="freezeNoTicketGen(row)"
                              :title="t('content.printticketcopy')">
                              <i class="material-icons material-symbols-outlined">file_copy</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle" id="unsizeModal"
                              data-bs-toggle="modal" data-bs-target="#unseizeModal" v-show="off">
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              v-show="selectRdo === '1'" @click="editRowItemsFreeze(row)"
                              :title="t('content.unfreeze')">
                              <i class="material-icons material-symbols-outlined">lock_open</i>
                            </material-button>

                          </td>
                        </tr>
                        <tr v-if="listhistory.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="14" style="text-align: right">Total Rows : {{ totalRows_sH }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listhistory.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageH" :total-items="totalRowsH"
                          :items-per-page="perPageH" @click="onClickHandlerH" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Attachments Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 520px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                  @click="visible_g = false">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div id="attachmentsImages" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators" id="demo2">
                  </div>
                  <div class="carousel-inner" id="demo3">
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Inform Ticket Lost Dialog -->
        <div id="lostTicketModal" class="modal fade" tabindex="-1" aria-labelledby="lostTicketModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.informlost") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="lostclose">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <!-- <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.amndantinfo") }}
                        </label>
                      </div>
                      <material-input id="informdate" :value="alertData.informdate" type="date" :disabled="true" />
                    </div>

                    <div class="col-lg-8">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label>
                      </div>
                      <material-input id="attachments" :value="alertData.file" type="file" multiple />
                    </div> -->

                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.note") }}
                        </label>
                      </div>
                      <Textarea rows="3" v-model="lost_detail.remark" class="w-100" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="lost_detail.remark === ''" @click="addLost">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Inform Seize Dialog -->
        <div id="seizeModal" class="modal fade" tabindex="-1" aria-labelledby="seizeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.informseize") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label>
                      </div>
                      <input id="uploadAddFreeze" type="file" accept="application/pdf,image/*" @change="addFile" />
                    </div>

                    <div class="col-lg-6">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.edate") }}
                        </label>
                      </div>
                      <VueDatePicker v-model="freeze_detail.freezeToDate" :format="format" auto-apply :teleport="true"
                        :min-date="new Date()" :enable-time-picker="false" month-name-format="long" :clearable="false"
                        :text-input="textInputOptions" fluid />
                    </div>

                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.note") }}
                        </label>
                      </div>
                      <Textarea rows="3" v-model="freeze_detail.remark" class="w-100" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="freeze_detail.remark === ''" @click="addFreeze">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Update Lost Dialog -->
        <div id="updateLostModal" class="modal fade" tabindex="-1" aria-labelledby="seizeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.informseize") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label>
                      </div>
                      <input id="uploadUpdateLost" type="file" accept="application/pdf,image/*" @change="addFile" />
                    </div>


                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.note") }}
                        </label>
                      </div>
                      <Textarea rows="3" v-model="lost_detail.remark" class="w-100" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="lost_detail.remark === ''" @click="updateLost">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Update Freeze Dialog -->
        <div id="updateFreezeModal" class="modal fade" tabindex="-1" aria-labelledby="seizeModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.informseize") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label>
                      </div>
                      <input id="uploadUpdateFreeze" type="file" accept="application/pdf,image/*" @change="addFile" />
                    </div>

                    <div class="col-lg-6">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.edate") }}
                        </label>
                      </div>
                      <VueDatePicker v-model="freeze_detail.freezeToDate" :format="format" auto-apply :teleport="true"
                        :enable-time-picker="false" month-name-format="long" :clearable="false"
                        :text-input="textInputOptions" fluid />
                    </div>

                    <div class="col-12 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.note") }}
                        </label>
                      </div>
                      <Textarea rows="3" v-model="freeze_detail.remark" class="w-100" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="freeze_detail.remark === ''" @click="updateFreeze">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Copy Print Dialog -->
        <div id="copyPrintModal" class="modal fade" tabindex="-1" aria-labelledby="copyPrintModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.copyprint") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.officername") }}
                        </label>
                      </div>
                      <material-input id="officename" v-model="freeze_detail.plName" type="text" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.position") }}
                        </label>
                      </div>
                      <material-input id="position" v-model="freeze_detail.plPosition" type="text" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.placeon") }}
                        </label>
                      </div>
                      <material-input id="placeon" v-model="freeze_detail.plWork" type="text" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.date") }}
                        </label>
                      </div>
                      <!-- <material-input id="tel" type="tel" /> -->
                      <VueDatePicker v-model="freeze_detail.plCutDate" :format="formatdt" auto-apply :teleport="true"
                        :enable-time-picker="true" month-name-format="long" :clearable="false"
                        :text-input="textInputOptionsdt" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.caseno") }}
                        </label>
                      </div>
                      <material-input id="caseno" v-model="freeze_detail.plCaseId" type="text" />
                    </div>

                    <div class="col-lg-4">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.fromplace") }}
                        </label>
                      </div>
                      <material-input id="fromplace" v-model="freeze_detail.plCaseOwner" type="text" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  :disabled="freeze_detail.plName === '' || freeze_detail.plPosition === '' || freeze_detail.plWork === ''
                    || freeze_detail.plCaseId === '' || freeze_detail.plCaseOwner === '' || freeze_detail.plCutDate === null" @click="updateFreezeGen">
                  {{ savePrint }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Unseize Dialog -->
        <div id="unseizeModal" class="modal fade" tabindex="-1" aria-labelledby="unseizeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.unseize") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="d-flex align-items-center mb-1">
                        <label class="form-label ms-0 mb-0 me-3">
                          {{ $t("content.file") }}
                        </label>
                      </div>
                      <input id="uploadUnfreeze" type="file" accept="application/pdf,image/*" @change="addFile" />
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ cancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="unFreeze">
                  {{ save }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <!-- <th style="width: 100px">{{ $t("content.ccode") }}</th> -->
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docno") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td></td>
                          <td>
                            <material-input v-model="nameThC" type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input v-model="nameEnC" type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input v-model="docNumberC" type="text" :label="t('content.docno')" />
                          </td>
                          <td>
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              :disabled="nameThC.length < 2 && nameEnC.length < 2 && docNumberC.length < 5"
                              @click="loadCustomerAfListApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in customerlist" :key="index" @click="selectCusAf(row)">
                          <td>{{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}</td>
                          <td>{{ row.nameTh }}</td>
                          <td>{{ row.nameEn }}</td>
                          <td colspan="2">{{ row.docNumber }}</td>
                        </tr>
                        <tr v-if="customerlist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerlist.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                          :items-per-page="perPageC" @click="onClickHandlerC" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Detail Modal -->
        <div id="lookupModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width:1240px;">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">

                <div class="row">
                  <div class="col-lg-6 mt-2">
                    <div id="lookupImages" class="carousel slide" data-bs-ride="carousel" style="max-width: 520px">
                      <div class="carousel-indicators" id="demo1">
                      </div>
                      <div class="carousel-inner" id="demo">
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#lookupImages"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#lookupImages"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-6 mt-2">
                    <div v-if="workcaseDetail !== null" style="overflow-x:auto;">
                      <div>
                        <table class="table table-sm table-borderless">
                          <tbody>
                            <tr>

                              <td class="fs-14 text-bold">{{ $t("content.ticketno") }} :
                                <span v-if="workcaseDetail.tkRefNumber !== ''">
                                  {{ workcaseDetail.tkRefNumber }} &nbsp;
                                  <span @click='copyBtn()' style="cursor: pointer">
                                    <i class="inline-icon material-icons">content_copy</i>
                                  </span>
                                </span>
                                <span v-else>-</span>
                              </td>
                              <td class="fs-14 text-bold">
                              </td>

                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.trans") }} :
                                {{ checkTransaction(workcaseDetail.transactionType) }}
                              </td>

                              <td class="fs-14 text-bold">{{ $t("content.statusticket") }} :
                                <span v-if="workcaseDetail.status === 'FINISH'" style="color:green;">
                                  {{ tk_status }}
                                </span>
                                <span v-if="workcaseDetail.status === 'FREEZE' || workcaseDetail.status === 'TERMINATE'"
                                  style="color:red;">
                                  {{ tk_status }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount === 0">
                              <td class="fs-14 text-bold"> {{ $t("content.lasttrf") }} : {{
                                format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.amnduedate") }} :
                                <span style="color:red;">
                                  {{ format_date(workcaseDetail.dueDate) }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.lasttrf") }} : {{
                                format_datetime(workcaseDetail.workItems[0].startTime) }}</td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2">
                                {{ $t("content.amnduedate") }} :
                                <span style="color:red;">
                                  {{ format_date(workcaseDetail.dueDate) }} (ขอเลื่อนเป็นวันที่ {{
                                    format_date(workcaseDetail.postponeDate) }})
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.postponeCount !== 0">
                              <td class="fs-14 text-bold" colspan="2">
                                {{ $t("content.deferpast") }} :
                                <span @click='NewTab()' style="text-decoration: underline;color:red; cursor: pointer">
                                  {{ workcaseDetail.postponeCount }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">
                                {{ $t("content.monthlyprof") }} : {{
                                  formatNumberDecimalString(workcaseDetail.interestPerMonth) }}
                              </td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.currentprof") }} :
                                <span style="color:red;">
                                  {{ formatNumberDecimalString(int_cal) }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">
                                {{ $t("content.prointerest") }} :
                                <span
                                  v-if="workcaseDetail.interestPromotion === null || workcaseDetail.interestPromotion === 0">
                                  -
                                </span>
                                <span style="color:blue;" v-else>
                                  {{ formatNumberDecimalString(workcaseDetail.interestPromotion) }}
                                </span>
                              </td>
                              <td class="fs-14 text-bold">
                                {{ $t("content.periodpromotion") }} :
                                <span v-if="workcaseDetail.promotionDuration === 0">
                                  -
                                </span>
                                <span style="color:blue;" v-else>
                                  {{ workcaseDetail.promotionDuration }} เดือน
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.amnqtypiece") }} :
                                {{ formatNumberString(workcaseDetail.ticket.totalQuantity) }}
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.allweight") }} :
                                {{ formatNumberDecimalString(workcaseDetail.ticket.totalWeight) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.amnanalysval") }} :
                                <span v-if="workcaseDetail.transactionType !== 'REDEEM'">
                                  {{ formatNumberDecimalString(workcaseDetail.newPawnValue) }}
                                </span>
                                <span v-else>
                                  {{ formatNumberDecimalString(workcaseDetail.pawnValue) }}
                                </span>
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.amnmaxvalall") }} :
                                {{ formatNumberDecimalString(workcaseDetail.ticket.totalFullValue) }}
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold">{{ $t("content.statusticketlost") }} :
                                <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> ไม่มี
                                </span>
                                <span v-else style="color:red;">
                                  มี ({{ workcaseDetail.lostCount }})
                                </span>
                              </td>
                              <td class="fs-14 text-bold">{{ $t("content.refnolost") }} :
                                <span v-if="workcaseDetail.lostCount === 0" style="color:green;"> -
                                </span>
                                <span v-else style="color:red;">
                                  {{ workcaseDetail.lostRefNumber }}
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.workItems[0].represent">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.represent") }} :
                                <span style="color:red;">
                                  {{ workcaseDetail.workItems[0].customerName }}
                                  <!-- {{ workcaseDetail.tkCustomerInfo.name }} -->
                                </span>
                              </td>
                            </tr>
                            <tr v-if="workcaseDetail.workItems[0].changeName">
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.changeName") }} :
                                <span style="color:red;">
                                  {{ workcaseDetail.workItems[0].tkCustomerName }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.amnlocationasset") }} :
                                <span v-if="location !== ''">
                                  {{ location.fullName }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td class="fs-14 text-bold" colspan="2"> {{ $t("content.num") }} :
                                <span v-if="workcaseDetail.ticket.stockNumber !== null">
                                  {{ workcaseDetail.ticket.stockNumber }}
                                </span>
                                <span v-else>
                                  -
                                </span>

                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <span v-if="listinfo.length !== 0">
                          <table class="table table-sm table-borderless">
                            <tbody>
                              <tr>
                                <td class="fs-14 text-bold">
                                  <span style="text-decoration:underline;">
                                    {{ $t("content.amnassetdetail") }}
                                  </span>
                                </td>
                              </tr>

                              <tr v-for="(row, index) in listinfo" :key="index">
                                <td class="fs-14 text-bold">
                                  {{ formatNumberString(index + 1) }}. {{ row.remark }}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                        </span>
                        <span v-else>
                          <tr>
                            <td class="fs-14 text-bold">
                              <span style="text-decoration:underline;">
                                {{ $t("content.amnassetdetail") }}
                              </span>
                              : -
                            </td>

                          </tr>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr v-if="paymentE !== ''" />
                <div class="col-12 mt-2" v-if="paymentE !== ''">
                  <div class="fs-14 text-bold">
                    <span style="text-decoration:underline;">
                      {{ $t("content.amnfinanceinfo") }}
                    </span>
                  </div>
                  <pre></pre>
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-flush">
                        <thead class="thead-light">
                          <tr>
                            <th>#</th>
                            <th>{{ $t("content.ticketno") }}</th>
                            <th>{{ $t("content.amnbiz") }}</th>
                            <th colspan="12" class="align-top text-center">
                              {{ $t("content.channelpay") }}
                            </th>
                          </tr>
                          <tr>
                            <th colspan="3"></th>
                            <th class="align-top text-center">
                              {{ $t("content.amncash") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.fee") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.amntransfer") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.fee") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.amntransfer_promt_mobile") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.fee") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.amntransfer_promt_taxid") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.fee") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.amncheque") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.fee") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.amncreditcard") }}
                            </th>
                            <th class="align-top text-center">
                              {{ $t("content.fee") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, index) in paymentE.workCases" :key="index">
                            <template v-if="row.ticket !== null">
                              <td class="text-sm font-weight-normal text-center">{{ formatNumberString(index + 1) }}
                              </td>
                              <td class="text-sm font-weight-normal text-center">{{ row.tkRefNumber }}</td>
                              <td class="text-sm font-weight-normal text-center">{{
                                checkTransaction(row.transactionType) }}</td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[0].pay) }}
                                </span>
                                <span v-if="payment_l[0].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[0].receive) }}
                                </span>
                                <span v-if="payment_l[0].pay === 0 && payment_l[0].receive === 0">
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[0].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[0].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[1].pay) }}
                                </span>
                                <span v-if="payment_l[1].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[1].receive) }}
                                </span>
                                <span v-if="payment_l[1].pay === 0 && payment_l[1].receive === 0">
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[1].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[1].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[4].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[4].pay) }}
                                </span>
                                <span v-if="payment_l[4].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[4].receive) }}
                                </span>
                                <span v-if="payment_l[4].pay === 0 && payment_l[4].receive === 0">
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[4].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[4].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[5].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[5].pay) }}
                                </span>
                                <span v-if="payment_l[5].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[5].receive) }}
                                </span>
                                <span v-if="payment_l[5].pay === 0 && payment_l[5].receive === 0">
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[5].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[5].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[3].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[3].pay) }}
                                </span>
                                <span v-if="payment_l[3].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[3].receive) }}
                                </span>
                                <span v-if="payment_l[3].pay === 0 && payment_l[3].receive === 0">
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[3].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[3].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[2].pay > 0" style="color:red;">
                                  {{ formatNumberDecimalString(payment_l[2].pay) }}
                                </span>
                                <span v-if="payment_l[2].receive > 0" style="color:green;">
                                  {{ formatNumberDecimalString(payment_l[2].receive) }}
                                </span>
                                <span v-if="payment_l[2].pay === 0 && payment_l[2].receive === 0">
                                  -
                                </span>
                              </td>
                              <td v-if="index == 0" class="text-sm font-weight-normal text-center"
                                :rowspan="paymentE.workCases.length">
                                <span v-if="payment_l[2].fee > 0" style="color:blue;">
                                  {{ formatNumberDecimalString(payment_l[2].fee) }}
                                </span>
                                <span v-else>
                                  -
                                </span>
                              </td>

                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import ProgressSpinner from 'primevue/progressspinner';
import { reactive, computed, ref, inject, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Textarea from 'primevue/textarea';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ExampleFive from "@/examples/ExampleOne";

import axios from "axios";
import * as st from "@/config/setting.js";
import moment from 'moment'
import noImg from "@/assets/img/noimg.png"


import { useCustomerStore } from '@/stores/customer'
import { useWorkCaseStore } from '@/stores/workcase'
import { useInterestStore } from '@/stores/interest'
import { useLocationStore } from '@/stores/location'
import { usePaymentGroupStore } from '@/stores/paymentgroup'
import { useLostStore } from '@/stores/lost'
import { useFreezeStore } from '@/stores/freeze'
import { useDocumentStore } from '@/stores/document'



const storecus = useCustomerStore()
const storewc = useWorkCaseStore()
const storei = useInterestStore()
const storel = useLocationStore()
const storepay = usePaymentGroupStore()
const storelost = useLostStore()
const storefreeze = useFreezeStore()
const storedoc = useDocumentStore()


const { t } = useI18n();
const swal = inject("$swal");

const selectSeize = ref('0');
const selectRdo = ref('');

const activeTab = ref('tab1');

const save = computed(() => t("buttons.save"));
const cancel = computed(() => t("buttons.canc"));
const savePrint = computed(() => t("buttons.savePrint"));

const btnEdit = computed(() => t("buttons.edit"));
const btnSearch = computed(() => t("buttons.sear"));
const btnFile = computed(() => t('buttons.seed') + t("content.file"));

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRows_sC = ref();

const currentPageH = ref(1);
const totalRowsH = ref(1);
const perPageH = ref(10);
const totalRows_sH = ref();

const nameThC = ref('')
const nameEnC = ref('')
const docNumberC = ref('')


const listinfo = ref([]);
const workcaseDetail = ref(null);
const info_cat = ref('');
const int_cal = ref(0);
const tk_status = ref('');
const location = ref('');

const cusUid_search = ref('');
const cusName_search = ref('');
const customerlist = ref([])

const images = ref([])
const images_f = ref([])
const images_l = ref([])

const payment_l = ref([])
const paymentE = ref('');

const loading = ref(false);

const tk_search = ref('');
const listdata = ref([])
const listhistory = ref([])

const cusUidH_search = ref('');
const cusNameH_search = ref('');
const tkH_search = ref('');

const thumb_id = ref('');



const lost_detail = ref({
  uid: '',
  workCaseUid: '', // always required
  remark: '',
  version: '',
  plDocUid: '',
  file: null
})


const freeze_detail = ref({
  uid: '',
  workCaseUid: '', // always required
  remark: '',
  version: '',
  plDocUid: '0',
  file: null,
  freezeToDate: null,
  plName: '',
  plPosition: '',
  plWork: '',
  plCaseId: '',
  plCaseOwner: '',
  plCutDate: new Date(),
  unFreezeDocUid: '0',
})

const thumb_name = ref("");
const thumb = ref(null);
const file_d = ref([]);
const preview_a = ref("");
const preview_e = ref("");

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const formatdt = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY HH:mm');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};

const textInputOptionsdt = {
  format: 'dd/MM/yyyy HH:mm'
};

const onClickHandler = (page) => {
  // console.log('onClickHandler :' + page);
  updatePagination()
};

const onClickHandlerC = (page) => {
  updatePaginationC()
};

const onClickHandlerH = (page) => {
  // updatePaginationC()
  if (selectRdo.value === '0') {
    updatePaginationLost()
  } else if (selectRdo.value === '1') {
    updatePaginationFreeze()
  }
};


const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val);
};

const NewTab = async () => {
  sessionStorage.removeItem('deferWorkcaseUid');
  sessionStorage.setItem("deferWorkcaseUid", workcaseDetail.value.uid);
  if (sessionStorage.getItem('deferWorkcaseUid') !== null) {
    const win = window.open("#/generals/defer_items", "_blank");
    win.focus();
    sessionStorage.removeItem('deferWorkcaseUid');
  }
}

function copyBtn() {
  navigator.clipboard.writeText(workcaseDetail.value.tkRefNumber);
}

function cancelDataBtn() {
  cusUid_search.value = '';
  cusName_search.value = '';
  tk_search.value = '';
  currentPage.value = 1
  totalRows_s.value = ''
  listdata.value = []
  const currentPage = ref(1);
}

function cancelHistoryBtn() {
  cusUidH_search.value = '';
  cusNameH_search.value = '';
  tkH_search.value = '';
  currentPageH.value = 1
  listhistory.value = []
  selectSeize.value = '0';
  selectRdo.value = '';
  totalRows_sH.value = ''
}




const loadDataApi = async (val) => {
  let data = "";

  if (cusUid_search.value !== '' || tk_search.value !== '') {
    currentPage.value = 1
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: "",
    toDate: "",
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storelost.lostSearchFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listdata.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}


async function onClickTab(val) {
  if (val === '1') {
    activeTab.value = 'tab1'
    if (cusUid_search.value !== '' || tk_search.value !== '') {
      loadDataApi()
    }
    await setfirstTab()
  } else if (val === '2') {
    activeTab.value = 'tab2'
    if (cusUidH_search.value !== '' || tkH_search.value !== '') {
      chkSelectHistory()
    }
    await setsecondTab()
  }
}

async function setfirstTab() {
  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
}


async function setsecondTab() {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.remove('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.remove('show', 'active')
  }

  if (document.getElementById("tab2-tab")) {
    document.getElementById("tab2-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab2")) {
    document.getElementById("tab2").classList.add('show', 'active')
  }
}


async function addFile(e) {
  file_d.value = []
  thumb.value = null
  thumb_name.value = ""
  preview_a.value = ""
  preview_e.value = ""
  let date = new Date()
  if (e.target.files[0] !== undefined) {
    file_d.value = e.target.files[0];
    thumb.value = await toBase64(e.target.files[0])
    thumb_name.value = 'file-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
    if (e.target.files[0].type === "image/jpeg") {
      thumb_name.value = thumb_name.value + ".jpg"
    } else if (e.target.files[0].type === "image/png") {
      thumb_name.value = thumb_name.value + ".png"
    } else if (e.target.files[0].type === 'application/pdf') {
      thumb_name.value = thumb_name.value + ".pdf"
    }
  }

}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});



async function openDetail(item) {

  workcaseDetail.value = null
  images.value = []
  images_l.value = []
  listinfo.value = []
  info_cat.value = ''
  tk_status.value = ''
  int_cal.value = 0
  location.value = ''
  if (activeTab.value === 'tab1') {
    await getworkCaseInfo(item.uid)
  } else if (activeTab.value === 'tab2') {
    await getworkCaseInfo(item.workCaseUid)
  }

  if (workcaseDetail.value != null) {
    loading.value = true
    // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
    await detailPayment(workcaseDetail.value.paymentGroupUid)
    images_l.value = workcaseDetail.value.ticket.images
    listinfo.value = workcaseDetail.value.ticket.collaterals
    await locationInfo()
    if (workcaseDetail.value.transactionType !== 'REDEEM') {
      await calIntInt();
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ปกติ'
      } else if (workcaseDetail.value.status === 'FREEZE') {
        tk_status.value = 'อายัด'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      }

    } else {
      if (workcaseDetail.value.status === 'FINISH') {
        tk_status.value = 'ไถ่ถอน'
      } else if (workcaseDetail.value.status === 'TERMINATE') {
        tk_status.value = 'ยกเลิก'
      }
    }

    if (images_l.value.length > 0) {
      getTicketImage()
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }
  } else {
    // console.log('workcaseDetail null : ' + item.uid);
    loading.value = false
  }
}


function clearLostItem() {
  thumb.value = null
  lost_detail.value = {
    uid: '',
    workCaseUid: '', // always required
    remark: '',
    version: '',
    plDocUid: '0',
    file: null
  }

}

function clearFreezeItem() {
  thumb.value = null
  freeze_detail.value = {
    uid: '',
    workCaseUid: '', // always required
    remark: '',
    version: '',
    plDocUid: '0',
    file: null,
    freezeToDate: null,
    plName: '',
    plPosition: '',
    plWork: '',
    plCaseId: '',
    plCaseOwner: '',
    plCutDate: new Date(),
    unFreezeDocUid: '0',

  }

}

async function openDialogLostAdd(item) {
  // console.log('lost : ' + JSON.stringify(item));
  clearLostItem()
  lost_detail.value.workCaseUid = item.uid
  document.getElementById("lostmodal").click();

}


async function openDialogFreezeAdd(item) {
  // console.log('freeze : ' + JSON.stringify(item));
  clearFreezeItem()
  freeze_detail.value.workCaseUid = item.uid
  document.getElementById("uploadAddFreeze").value = null;
  document.getElementById("freezemodal").click();

}

async function openDialogUpdate(item) {
  if (selectRdo.value === '0') {
    clearLostItem()
    lost_detail.value = {
      uid: item.uid,
      workCaseUid: item.workCaseUid, // always required
      remark: item.remark,
      version: item.version,
      plDocUid: item.plDocUid,
      file: null
    }
    document.getElementById("uploadUpdateLost").value = null;
    document.getElementById("updateLost").click()
  } else if (selectRdo.value === '1') {
    // console.log('openDialogUpdate : ' + JSON.stringify(item));
    clearFreezeItem()
    freeze_detail.value = {
      uid: item.uid,
      workCaseUid: item.workCaseUid, // always required
      remark: item.remark,
      version: item.version,
      plDocUid: item.plDocUid,
      file: null,
      plName: item.plName,
      plPosition: item.plPosition,
      plWork: item.plWork,
      plCaseId: item.plCaseId,
      plCaseOwner: item.plCaseOwner,
      unFreezeDocUid: item.unFreezeDocUid
    }
    if (item.freezeToDate !== null) {
      freeze_detail.value.freezeToDate = new Date(item.freezeToDate)
    }
    document.getElementById("uploadUpdateFreeze").value = null;
    document.getElementById("updateFreeze").click()
  }
}

async function openDialogCopy(item) {
  clearFreezeItem()
  freeze_detail.value = {
    uid: item.uid,
    workCaseUid: item.workCaseUid, // always required
    remark: item.remark,
    version: item.version,
    plDocUid: item.plDocUid,
    file: null,
    freezeToDate: item.freezeToDate,
    plName: item.plName,
    plPosition: item.plPosition,
    plWork: item.plWork,
    plCaseId: item.plCaseId,
    plCaseOwner: item.plCaseOwner,
    plCutDate: item.plCutDate,
    unFreezeDocUid: item.unFreezeDocUid

  }
  if (item.plCutDate !== null) {
    freeze_detail.value.plCutDate = new Date(item.plCutDate)
  } else {
    freeze_detail.value.plCutDate = new Date()
  }

  if (item.plName === null) {
    freeze_detail.value.plName = ''
  }

  if (item.plPosition === null) {
    freeze_detail.value.plPosition = ''
  }
  if (item.plWork === null) {
    freeze_detail.value.plWork = ''
  }

  if (item.plCaseId === null) {
    freeze_detail.value.plCaseId = ''
  }

  if (item.plCaseOwner === null) {
    freeze_detail.value.plCaseOwner = ''
  }


  document.getElementById("copyModal").click()
}

async function viewHistory(item) {
  tkH_search.value = item.refNumber
  selectSeize.value = '0'
  onClickTab('2')
}


async function openDialogCus() {

  if (activeTab.value === 'tab1') {
    cusUid_search.value = ''
    cusName_search.value = ''
  } else if (activeTab.value === 'tab2') {
    cusUidH_search.value = ''
    cusNameH_search.value = ''
  }
  nameThC.value = ''
  nameEnC.value = ''
  docNumberC.value = ''
  customerlist.value = []
  document.getElementById("searchNameCus").click();
}


async function selectCusAf(item) {
  // console.log('selectCusAf : ' + JSON.stringify(item));
  if (activeTab.value === 'tab1') {
    cusUid_search.value = item.uid
    cusName_search.value = item.nameTh
  } else if (activeTab.value === 'tab2') {
    cusUidH_search.value = item.uid
    cusNameH_search.value = item.nameTh
  }
  document.getElementById("closeModal").click();
}


async function loadCustomerAfListApi() {
  currentPageC.value = 1
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        customerlist.value = listd;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}



async function updatePaginationC() {
  customerlist.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameEn: nameEnC.value,
    nameTh: nameThC.value,
    docNumber: docNumberC.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerlist.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRows_sC.value = formatNumberString(totalRowsC.value)
      }
    }
  }
}


async function updatePagination() {
  listdata.value = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    cusUid: cusUid_search.value,
    refNumber: tk_search.value,
    fromDate: "",
    toDate: "",
  };

  const payload = data;

  const response = await storelost.lostSearchFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listdata.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }

}

async function chkSelectHistory() {
  selectRdo.value = selectSeize.value
  listhistory.value = []
  if (selectRdo.value === '0') {
    await loadLostApi()
  } else if (selectRdo.value === '1') {
    await loadFreezeApi()
  }
}

async function loadLostApi() {
  if (cusUidH_search.value !== '' || tkH_search.value !== '') {
    currentPageH.value = 1
  }
  let data = "";
  data = {
    index: currentPageH.value - 1,
    size: perPageH.value,
    cusUid: cusUidH_search.value,
    refNumber: tkH_search.value,
  };

  const payload = data;
  // console.log("pay : " + JSON.stringify(payload));
  const response = await storelost.lostListFetch(payload);
  // console.log("loadLostApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRowsH.value = response.data.data.ext.total
        totalRows_sH.value = formatNumberString(totalRowsH.value)
      }
    }
  }

}




async function loadFreezeApi() {
  if (cusUidH_search.value !== '' || tkH_search.value !== '') {
    currentPageH.value = 1
  }
  let data = "";
  data = {
    index: currentPageH.value - 1,
    size: perPageH.value,
    cusUid: cusUidH_search.value,
    refNumber: tkH_search.value,
  };

  const payload = data;
  // console.log("pay : " + JSON.stringify(payload));
  const response = await storefreeze.freezeListFetch(payload);
  // console.log("loadFreezeApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRowsH.value = response.data.data.ext.total
        totalRows_sH.value = formatNumberString(totalRowsH.value)
      }
    }
  }

}

async function updatePaginationLost() {
  listhistory.value = []
  let data = "";
  data = {
    index: currentPageH.value - 1,
    size: perPageH.value,
    cusUid: cusUidH_search.value,
    refNumber: tkH_search.value,
  };

  const payload = data;
  const response = await storelost.lostSearchFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRowsH.value = response.data.data.ext.total
        totalRows_sH.value = formatNumberString(totalRowsH.value)
      }
    }
  }

}


async function updatePaginationFreeze() {
  listhistory.value = []
  let data = "";
  data = {
    index: currentPageH.value - 1,
    size: perPageH.value,
    cusUid: cusUidH_search.value,
    refNumber: tkH_search.value,
  };

  const payload = data;
  const response = await storefreeze.freezeListFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listhistory.value = response.data.data.list;
        totalRowsH.value = response.data.data.ext.total
        totalRows_sH.value = formatNumberString(totalRowsH.value)
      }
    }
  }

}

function checkTransaction(str) {
  // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
  let side = ''
  if (str === 'PAWN') {
    side = 'จำนำ'
  } else if (str === 'INTEREST') {
    side = 'ต่อดอกเบี้ย'
  } else if (str === 'REDEEM') {
    side = 'ไถ่ถอน'
  } else if (str === 'INC_PRINCIPLE') {
    side = 'เพิ่มต้น'
  } else if (str === 'DEC_PRINCIPLE') {
    side = 'ลดต้น'
  }
  return side
}

function checkTicketStatus(str) {
  let side = ''
  if (str === 'FINISH') {
    side = 'ปกติ'
  } else if (str === 'FREEZE') {
    side = 'อายัด'
  } else if (str === 'LOST') {
    side = 'หาย'
  }
  return side
}

async function getFileTicket(item) {
  loading.value = true
  let url = []
  images.value = []
  url.push(String(st.url_api + "/doc-svc/document/info/" + item.plDocUid));
  // console.log("url : " + JSON.stringify(url));
  if (url.length > 0) {
    await getFile(url)
    // setTimeout(3000);
    if (images.value.length > 0) {
      if (images.value[0].title === 'application/pdf') {
        window.open(images.value[0].src, '_blank').focus();
      } else {
        setGallery2()
        document.getElementById("attachmenDialog").click();
      }

      loading.value = false
    }
  }
}

async function getTicketImage() {

  if (images.value.length === 0) {
    let url = []
    images.value = []
    for (let i = 0; i < images_l.value.length; i++) {
      // console.log("logoUid : " + item.images[i].imageUid);
      if (images_l.value[i].imageUid !== "0") {
        url.push(String(st.url_api + "/doc-svc/document/info/" + images_l.value[i].imageUid));
      }
    }

    if (url.length > 0) {
      await getFile(url)
      // setTimeout(3000);
      if (images.value.length > 0) {
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      } else {
        let obj = {
          src: noImg,
          thumbnailImageSrc: noImg,
          alt: '',
          title: ''
        }
        images.value.push(obj)
        setGallery()
        document.getElementById("detailModal").click();
        loading.value = false
      }
    } else {
      let obj = {
        src: noImg,
        thumbnailImageSrc: noImg,
        alt: '',
        title: ''
      }
      images.value.push(obj)
      setGallery()
      document.getElementById("detailModal").click();
      loading.value = false
    }

  } else {
    setGallery()
    document.getElementById("detailModal").click();
    loading.value = false
  }
}

async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {
        // console.log('file_L : ' + JSON.stringify(file_L))
        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            // console.log('file : ' + file.size + ' file t ' + file.type)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: file.type,
              filename: file_L[i].data.data.name,
            }
            images.value.push(obj)
          } else {
            // console.log('no dataFile ')
            loading.value = false
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  var ext = fileName.split('.').pop();
  let type = '';
  let imageContent = null
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  } else if (ext === 'pdf') {
    type = 'application/pdf'
  }

  if (type === 'application/pdf') {
    imageContent = atob(string64);
  } else {
    imageContent = atob(trimmedString);
  }

  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}

async function getworkCaseInfo(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;

  const response = await storewc.workCaseinfoFetch(payload);
  // console.log("getworkCaseInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        workcaseDetail.value = response.data.data
      }
    }
  }
}

function setGallery() {

  var x = "", i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item active"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#lookupImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo1").innerHTML = y;
  document.getElementById("demo").innerHTML = x;

}


function setGallery2() {

  var x = "", i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item active"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo2").innerHTML = y;
  document.getElementById("demo3").innerHTML = x;

}


async function calIntInt() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));
  let postponeDate = ''
  if (workcaseDetail.value.postponeCount != 0) {
    postponeDate = moment(workcaseDetail.value.postponeDate).format('YYYY-MM-DD')
  }
  let data = "";
  data = {
    pawnDate: moment(workcaseDetail.value.workItems[0].startTime).format('YYYY-MM-DD'),
    previousTxDate: '',
    postponeDate: postponeDate,
    pawnValue: workcaseDetail.value.newPawnValue,
    promotionUid: workcaseDetail.value.promotionUid,
    interestUid: workcaseDetail.value.interestUid,
  };
  const payload = data;
  const response = await storei.interestCalculateFetch(payload);
  // console.log("calIntInt : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        res = response.data.data
        int_cal.value = Number(res.overdueInterest) + Number(res.dueInterest)
        // console.log("calIntInt : " + JSON.stringify(res));
        // int_cal.value = Number(res.promoInterest) + Number(res.overdueInterest) + Number(res.dueInterest)
        // console.log("int_cal : " + int_cal.value);
      }
    }
  }
}

async function locationInfo() {
  // console.log('workcaseDetail : ' + JSON.stringify(workcaseDetail.value));

  let data = "";
  data = {
    shopUid: '',
    uid: workcaseDetail.value.ticket.locationUid,
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storel.locationInfoFetch(payload);
  // console.log("locationInfo : " + JSON.stringify(response.data));
  let res = ''
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        location.value = response.data.data
      }
    }
  }
}

async function detailPayment(item) {
  // console.log('detailPayment : ' + item);
  let data = "";
  data = {
    uid: item,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);

  const response = await storepay.paymentGroupinfoFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        paymentE.value = response.data.data
        filterPayType()
        // console.log('detailPayment : ' + JSON.stringify(paymentE.value));
      }
    }
  }
}


function filterPayType() {
  payment_l.value = []
  let pt = [{ name: 'CASH' },
  { name: 'BANK_TRANSFER' },
  { name: 'CREDIT_CARD' },
  { name: 'CHEQUE' },
  { name: 'PROMPT_PAY_MOBILE' },
  { name: 'PROMPT_PAY_TAX_ID' }]
  let lsum = []
  for (let i = 0; i < pt.length; i++) {
    lsum = filterSumPay(pt[i].name)
    if (lsum.length > 0) {
      payment_l.value.push({ name: pt[i].name, pay: lsum[0].pay, receive: lsum[0].receive, fee: lsum[0].fee })
    } else {
      payment_l.value.push({ name: pt[i].name, pay: 0, receive: 0, fee: 0 })
    }
  }
}

function filterSumPay(str) {
  let nump = 0, numr = 0, numf = 0
  let lp = paymentE.value.payments.filter((c) => c.type === str);
  if (lp.length > 0) {
    let pay = 0
    let receive = 0
    let fee = 0
    for (let i = 0; i < lp.length; i++) {
      pay = pay + lp[i].pay
      receive = receive + lp[i].receive
      fee = fee + lp[i].fee
    }
    nump = pay
    numr = receive
    numf = fee
  }
  let lockL = []
  lockL.push({ pay: nump, receive: numr, fee: numf })
  // console.log('lockL : ' + JSON.stringify(lockL));
  return lockL
}
function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}

function format_date(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

async function lostTicketGen(item) {
  await getlostTicket(item.uid)
}

async function freezeGen(item) {
  await getfreeze(item.uid)
}

async function freezeNoTicketGen(item) {
  await getfreezeNoTicket(item.uid)
}


async function getlostTicket(id) {
  // console.log('id : ' + id)
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/lostTicket/` + id, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    // console.log('url : ' + url)
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}

async function getfreeze(id) {
  // console.log('id : ' + id)
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/freeze/` + id, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    // console.log('url : ' + url)
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}

async function getfreezeNoTicket(id) {
  // console.log('id : ' + id)
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = key.accessToken;
  const response = await axios.get(st.url_api + `/docgen-svc/freeze/noTicket/` + id, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer'
  });

  if (response) {
    // console.log('blob - response ', response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    // console.log('url : ' + url)
    window.open(url, '_blank').focus();
  } else {
    swal("โหลด PDF ไม่สำเร็จ", "", "error");
  }
}

async function addLost() {
  const payload = lost_detail.value;
  const response = await storelost.lostNewFetch(payload);
  // console.log('addLost r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updateLost() {
  const payload = lost_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.plDocUid = thumb_id.value
  }
  const response = await storelost.lostUpdateFetch(payload);
  // console.log('addLost r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        chkSelectHistory()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function addFreeze() {
  // console.log('thumb : ' + thumb.value);
  // console.log('thumb n : ' + thumb_name.value);
  const payload = freeze_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.plDocUid = thumb_id.value
  } else {
    payload.plDocUid = '0'
  }
  if (payload.freezeToDate !== undefined) {
    payload.freezeToDate = moment(new Date(payload.freezeToDate)).format('YYYY-MM-DDT00:00:00')
  }
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storefreeze.freezeNewFetch(payload);
  if (response) {
    // console.log('addFreeze r : ' + JSON.stringify(response.data));
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateFreeze(val) {
  const payload = freeze_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
  }

  if (val !== 'unfreeze') {
    payload.plDocUid = thumb_id.value
  } else {
    payload.unFreezeDocUid = thumb_id.value
  }

  // console.log('freezeToDate : ' + payload.freezeToDate);
  if (payload.freezeToDate !== undefined) {
    payload.freezeToDate = moment(new Date(payload.freezeToDate)).format('YYYY-MM-DDT00:00:00')
  } else {
    payload.freezeToDate = null
  }
  // console.log('payload : ' + JSON.stringify(payload.freezeToDate));
  const response = await storefreeze.freezeUpdateFetch(payload);
  if (response) {
    // console.log('updateFreeze r : ' + JSON.stringify(response.data));
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (val !== 'unfreeze') {
          swal("บันทึกสำเร็จ", "", "success");
          chkSelectHistory()
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updateFreezeGen() {
  const payload = freeze_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.plDocUid = thumb_id.value
  }

  if (payload.freezeToDate !== undefined) {
    payload.freezeToDate = moment(new Date(payload.freezeToDate)).format('YYYY-MM-DDT00:00:00')
  } else {
    payload.freezeToDate = null
  }
  if (payload.plCutDate !== undefined) {
    payload.plCutDate = moment(new Date(payload.plCutDate)).format('YYYY-MM-DDTHH:mm:ss')
  } else {
    payload.plCutDate = null
  }

  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storefreeze.freezeUpdateFetch(payload);
  if (response) {
    // console.log('addFreeze r : ' + JSON.stringify(response.data));
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await freezeGen(payload)
        chkSelectHistory()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function unFreeze() {
  const payload = freeze_detail.value;
  if (thumb.value !== null) {
    await updateFreeze('unfreeze')
  }
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storefreeze.unFreezeFetch(payload);
  if (response) {
    // console.log('unFreeze r : ' + JSON.stringify(response.data));
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        chkSelectHistory()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addfileApi() {
  let data = "";
  data = {
    name: thumb_name.value,
    data: thumb.value,
  };

  const payload = data;
  const response = await storedoc.addFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function editRowItemsFreeze(item) {
  clearFreezeItem()
  freeze_detail.value = {
    uid: item.uid,
    workCaseUid: item.workCaseUid, // always required
    remark: item.remark,
    version: item.version,
    plDocUid: item.plDocUid,
    file: null,
    plName: item.plName,
    plPosition: item.plPosition,
    plWork: item.plWork,
    plCaseId: item.plCaseId,
    plCaseOwner: item.plCaseOwner,
    unFreezeDocUid: item.unFreezeDocUid
  }

  document.getElementById("uploadUnfreeze").value = null;
  document.getElementById("unsizeModal").click()
}

onMounted(() => {

  if (document.getElementById("tab1-tab")) {
    document.getElementById("tab1-tab").classList.add('show', 'active')
  }

  if (document.getElementById("tab1")) {
    document.getElementById("tab1").classList.add('show', 'active')
  }
});


</script>

<style lang="scss" scoped>
.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
